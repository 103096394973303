$dark-color: #ffffff;
$light-color: #333333;
$light-gray: #ecf0f1;
$light-hover-color: #bababa;
$dark-in-between-color: #d4d4d4;
$separating-line-color: #777777;
$hardcore-text-color: var(--on-primary) !important;
$important-text-color: #333333;
$placeholder-color: #969da2;
$text-color: var(--on-primary) !important;
$error-color: #FF3636;
$good-color: #24cc40;
$yellow-color: #24cc40;

$translucent-modal-background: #979797a6;

$button-font-size: 1rem;
$button-height: 3rem;

$normal-text-size: 1.25rem;
$big-text-size: 1.5rem;

$border-color: #d4d4d4;
$button-color-default: #0059F7;
$button-hover-color-default: #0059F7;
$button-text-color-default: white;
$button-color-pressed-default: #60a3de;

$stake-button-color: #24cc40;

$sidebar-value-color: #0059F7;
$tooltip-color: #FF3636;

$bad-color: #FF3636;
$x-color: #FF3636;
$important-button: #24cc40;
$profile-button: #f79400;

$core-post-color: #333333;
$comment-color: #0059F7;
$comment-hover: rgba(75, 181, 214, 0.158);
$post-text-color: white;
$grey: #a8a8a8;


$repost-color: green;
$repost-hover: #24cc4034;

$like-color: #FF3636;
$like-hover: #ff36362d;

$diamond-color: #0059F7;
$diamond-hover: #005bf734;

$link-btn-color: #0059F7;
$link-btn-hover:rgba(75, 181, 214, 0.158);

$landing-page-fc: #888;
$landing-page-dark: #1B1C1E;
//#212529
 //p
  //background-color: #6FCF97;
  //background-color: #18A0FB;
  //background-color: #EF8275;
  //background-color: #6FCF97;
  //background-color: #ce6060;
