$primary: #0059F7;
$danger: #E63946;
$secondary: #ecf0f1;
$success: #32ED4B;
$info: #689eff;
$feed-border-radius: 100px;
$landing-page-highlight: #00faff;

@import 'variables.scss';
@import 'app/theme/themes/index';
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

// regular style toast
@import '~ngx-toastr/toastr';

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.ttf')  format('truetype');
  font-weight: normal;
  ascent-override: 100%;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-SemiBold.ttf')  format('truetype');
  font-weight: 600;
  ascent-override: 100%;
}

@font-face {
  font-family: 'Druk';
  src: url('./assets/fonts/DrukCond-Super.ttf')  format('truetype');
  font-weight: 600;
  ascent-override: 100%;
}

// New themeing class
.main {
  background: var(--background);
  color: var(--text);
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
}

html {
  overflow-y: scroll;
  overscroll-behavior-y: none;

   // removes 300ms click delay on mobile browsers
   touch-action: manipulation;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  min-height: 100%; // allows for scrolling
}

// Fix any random overflow from causing weird
// white bars at the bottom of pages by hiding bars.
//
// This works because body has a min-height not a fixed height
body {
  overflow: hidden;
}

h1 {
  margin: 0;
  color: var(--text);
}

h2,h3,h4,h5,h6,p {
  margin: 0;
  color: var(--text);
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  font-weight: normal;
}

a {
  cursor: pointer; // for anchors without hrefs
  color: var(--link);
  &:hover, &:active, &:focus {
    text-decoration: inherit; // I prefer no underline when you hover over links
    color: var(--hover);
  }
}

.link--unstyled {
  color: inherit;
  text-decoration: inherit;
  &:hover, &:active, &:focus {
    text-decoration: inherit;
    color: inherit;
    outline: none;
  }
}

pre, code{
    white-space:normal;
}

.invisible {
  visibility: hidden !important;
  .diamond-icon {
    visibility: hidden !important;
  }
}

.countdown-timer {
  text-align: center;
  color: #252B37;
  animation: textColor 4s ease infinite;

  @keyframes textColor {
    0% {
    color: #d6b6fc;
    }
    50% {
      color: #0fffc1;
    }
    100% {
      color: #d6b6fc;
    }
  }
}

.countdown-timer-emoji-left {
  animation: leftToRight 4s linear infinite;

  @keyframes leftToRight {
      0% {
        margin-left: 0px;
      }
      50% {
        margin-left: 40px;
      }
      100% {
        margin-left: 0px;
      }
  }
}

.countdown-timer-emoji-right {
  animation: rightToLeft 4s linear infinite;

  @keyframes rightToLeft {
    0% {
      margin-right: 0px;
    }
    50% {
      margin-right: 40px;
    }
    100% {
      margin-right: 0px;
    }
  }
}


//
// v0 styles (start)
//

$global__top-bar__height: 60px;
$global__top-bar__desktop-height: 20px;
$global__nav__width: 271px;
$global__center__width: 600px;
$global__sidebar__width: 375px;
$global__content__width: $global__center__width + $global__sidebar__width;

$border-grey: #E5E5E5;
$staking-grey: #F0F0F0;
$light-grey-for-divider: #F8F8F8;
$light-grey-for-divider-2: #FAFAFA;
$buy-button-blue: var(--button);
// Font colors.
$light-font-color: #777777;
$normal-font-color: #222222;
$red-font-color: #fe3537;
$green-font-color: #19B028;

// For when you want a finger instead of an arrow.
.cursor-pointer {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

.cursor-inherit {
  cursor: inherit;
}

.center-col-width {
  width: 600px;
}

// Grey text colors
.text-black { color: #000000 }
.text-grey1 { color: #111111 }
.text-grey2 { color: #222222 } // mocks call this G0
.text-grey3 { color: #333333 }
.text-grey4 { color: #444444 }
.text-grey5 { color: var(--grey) } // mocks call this G1
.text-grey6 { color: #666666 }
.text-grey7 { color: #777777 }
.text-grey8 { color: #888888 }
.text-grey9 { color: #999999 }
.text-greyA { color: #AAAAAA }
.text-greyB { color: #BBBBBB }
.text-greyC { color: #CCCCCC }
.text-greyD { color: #DDDDDD }
.text-greyE { color: #EEEEEE }
.text-greyF { color: #FFFFFF }

.text-grey8A { color: #8A8A8A } // mocks call this G2
.text-greyE5 { color: #E5E5E5 } // mocks call this G3
.text-greyF5 { color: #F5F5F5 } // mocks call this G4


.text-grey-loading { color: var(--loading) }
// Set toast-info to use system blue
.toast-info { background-color: $buy-button-blue}

// font weights
// font-weight-light: 300
// font-weight-normal: 400
.font-weight-heavy { font-weight:00 !important; }
.font-weight-bold { font-weight: 600 !important; }
.font-weight-semibold { font-weight: 500 }
.font-weight-normal { font-weight: 400 }
// font-weight-bold: 700


// Widths
.w-10 { width: 10% }
.w-15 { width: 15% }
.w-20 { width: 20% }
.w-30 { width: 10% }
.w-40 { width: 40% }
.w-50 { width: 50% }
.w-60 { width: 60% }
.w-70 { width: 70% }
.w-80 { width: 80% }
.w-90 { width: 90% }
.w-100 { width: 100% }

.max-width-420px { max-width: 420px }

// Custom font classes.
.fc-default { color: var(--text); }
.fc-secondary { color: var(--text-secondary); }
.fc-muted { color: var(--text-muted); }
.fc-inverted { color: var(--text-inverted); }
.fc-blue { color: var(--highlight); }
.fc-light-blue { color: var(--highlight-secondary); }
.fc-red { color: var(--cred); }
.fc-green { color: var(--cgreen); }
.fc-success { color: var(--success); }
.fc-error { color: var(--error); }
.fc-landing-page { color: $landing-page-fc; }
.fc-landing-page-title { color: $landing-page-dark; }
.fc-none { color: rgba(0, 0, 0, 0); }

.z-1 { z-index: 1; }
.z-2 { z-index: 2; }

.font-weight-500 { font-weight: 500 }

.fs-1 { font-size: 2.5rem }
.fs-2 { font-size: 2rem }
.fs-3 { font-size: 1.75rem }

.fs-35px { font-size: 35px; }
.fs-32px { font-size: 32px; }
.fs-25px { font-size: 25px; }
.fs-24px { font-size: 24px; }
.fs-22px { font-size: 22px; }
.fs-20px { font-size: 20px; }
.fs-18px { font-size: 18px; }
.fs-17px { font-size: 17px; }
.fs-16px { font-size: 16px; }
.fs-15px { font-size: 15px; }
.fs-15px-important { font-size: 15px !important; }
.fs-14px { font-size: 14px; }
.fs-13px { font-size: 13px; }
.fs-12px { font-size: 12px !important; }
.fs-12px { font-size: 11px !important; }
.fs-10px { font-size: 10px !important; }
.fs-0px { font-size: 0px !important; }
// line heights
.lh-24px { line-height: 24px; }
.lh-20px { line-height: 20px; }
.lh-18px { line-height: 18px; }
.lh-16px { line-height: 16px; }
.lh-15px { line-height: 15px; }
.lh-14px { line-height: 14px; }
.lh-13px { line-height: 13px; }
.lh-12px { line-height: 12px; }
.roboto-regular { font-family: Gilroy, Arial, sans-serif }
.roboto-mono { font-family: Gilroy, Arial, sans-serif }

// Custom border classes.
.border-color-grey { border-color: var(--border) !important; }
.border-color-blue { border-color: $buy-button-blue; }
.tab-underline-active { border-bottom: solid $buy-button-blue 3px; }
.tab-underline-inactive { border-bottom: solid rgba(0,0,0,0) 3px; }

.background-color-grey { background-color: var(--border); } // mocks call this G4
.background-color-light-grey { background-color: var(--tile-secondary-bg); }
.background-color-light-grey-important { background-color: var(--background-secondary) !important }
.background-color-light-pink { background-color: lightpink; }
.background-color-white { background-color: var(--tile-bg); }
.background-color-main { background-color: var(--tile-bg); }
.background-color-form { background-color: var(--formbg); }
.background-color-secondary { background-color: var(--tile-secondary-bg); }
.background-color-background { background-color: var(--background); }
.background-color-lemon-chiffon { background-color: lemonchiffon; }
.background-color-transparent { background-color: transparent; }

// Custom padding classes.
.p-40px { padding: 40px; }
.pt-40px { padding-top: 40px; }
.pr-40px { padding-right: 40px; }
.pb-40px { padding-bottom: 40px; }
.pl-40px { padding-left: 40px; }
.py-40px { padding-top: 40px; padding-bottom: 40px; }
.px-40px { padding-left: 40px; padding-right: 40px; }

.p-35px { padding: 35px; }
.pt-35px { padding-top: 35px; }
.pr-35px { padding-right: 35px; }
.pb-35px { padding-bottom: 35px; }
.pl-35px { padding-left: 35px; }
.py-35px { padding-top: 35px; padding-bottom: 35px;}
.px-35px { padding-left: 35px; padding-right: 35px;}

.p-30px { padding: 30px; }
.pt-30px { padding-top: 30px; }
.pr-30px { padding-right: 30px; }
.pb-30px { padding-bottom: 30px; }
.pl-30px { padding-left: 30px; }
.py-30px { padding-top: 30px; padding-bottom: 30px;}
.px-30px { padding-left: 30px; padding-right: 30px;}

.p-30px { padding: 30px; }
.pt-30px { padding-top: 30px; }
.pr-30px { padding-right: 30px; }
.pb-30px { padding-bottom: 30px; }
.pl-30px { padding-left: 30px; }
.py-30px { padding-top: 30px; padding-bottom: 30px;}
.px-30px { padding-left: 30px; padding-right: 30px;}

.p-25px { padding: 25px; }
.pt-25px { padding-top: 25px; }
.pr-25px { padding-right: 25px; }
.pb-25px { padding-bottom: 25px; }
.pl-25px { padding-left: 25px; }
.py-25px { padding-top: 25px; padding-bottom: 25px;}
.px-25px { padding-left: 25px; padding-right: 25px;}

.p-20px { padding: 20px; }
.pt-20px { padding-top: 20px; }
.pr-20px { padding-right: 20px; }
.pb-20px { padding-bottom: 20px; }
.pl-20px { padding-left: 20px; }
.py-20px { padding-top: 20px; padding-bottom: 20px;}
.px-20px { padding-left: 20px; padding-right: 20px;}

.p-16px { padding: 16px; }
.pt-16px { padding-top: 16px; }
.pr-16px { padding-right: 16px; }
.pb-16px { padding-bottom: 16px; }
.pl-16px { padding-left: 16px; }
.py-16px { padding-top: 16px; padding-bottom: 16px;}
.px-16px { padding-left: 16px; padding-right: 16px;}

.p-15px { padding: 15px; }
.pt-15px { padding-top: 15px; }
.pr-15px { padding-right: 15px; }
.pb-15px { padding-bottom: 15px; }
.pl-15px { padding-left: 15px; }
.py-15px { padding-top: 15px; padding-bottom: 15px;}
.px-15px { padding-left: 15px; padding-right: 15px;}

.p-10px { padding: 10px; }
.pt-10px { padding-top: 10px; }
.pr-10px { padding-right: 10px; }
.pb-10px { padding-bottom: 10px; }
.pl-10px { padding-left: 10px; }
.py-10px { padding-top: 10px; padding-bottom: 10px;}
.px-10px { padding-left: 10px; padding-right: 10px;}

.p-8px { padding: 8px; }
.pt-8px { padding-top: 8px; }
.pr-8px { padding-right: 8px; }
.pb-8px { padding-bottom: 8px; }
.pl-8px { padding-left: 8px; }
.py-8px { padding-top: 8px; padding-bottom: 8px;}
.px-8px { padding-left: 8px; padding-right: 8px;}

.p-5px { padding: 5px; }
.pt-5px { padding-top: 5px; }
.pr-5px { padding-right: 5px; }
.pb-5px { padding-bottom: 5px; }
.pl-5px { padding-left: 5px; }
.py-5px { padding-top: 5px; padding-bottom: 5px;}
.px-5px { padding-left: 5px; padding-right: 5px;}

.p-2px { padding: 2px; }
.pt-2px { padding-top: 2px; }
.pr-2px { padding-right: 2px; }
.pb-2px { padding-bottom: 2px; }
.pl-2px { padding-left: 5px; }
.py-2px { padding-top: 2px; padding-bottom: 2px;}
.px-2px { padding-left: 2px; padding-right: 2px;}

// Zero padding classes are useful for things like bootstrap columns that have padding added.
.p-0px { padding: 0px; }
.pt-0px { padding-top: 0px; }
.pr-0px { padding-right: 0px; }
.pb-0px { padding-bottom: 0px; }
.pl-0px { padding-left: 0px; }
.py-0px { padding-top: 0px; padding-bottom: 0px;}
.px-0px { padding-left: 0px; padding-right: 0px;}

// Custom margin classes.
.m-0px { margin: 0px !important; }
.mt-0px { margin-top: 0px; }
.mr-0px { margin-right: 0px; }
.mb-0px { margin-bottom: 0px !important; }
.ml-0px { margin-left: 0px; }

.mt-2px { margin-top: 2px; }

.m-5px { margin: 5px; }
.mt-5px { margin-top: 5px; }
.mr-5px { margin-right: 5px; }
.mb-5px { margin-bottom: 5px; }
.ml-5px { margin-left: 5px; }

.m-8px { margin: 8px; }
.mt-8px { margin-top: 8px; }
.mr-8px { margin-right: 8px; }
.mb-8px { margin-bottom: 8px; }
.ml-8px { margin-left: 8px; }
.mx-8px { margin-left: 8px; margin-right: 8px; }

.m-10px { margin: 10px; }
.mt-10px { margin-top: 10px; }
.mr-10px { margin-right: 10px; }
.mb-10px { margin-bottom: 10px; }
.ml-10px { margin-left: 10px; }
.mx-10px { margin-left: 10px; margin-right: 10px; }
.my-10px { margin-top: 10px; margin-bottom: 10px; }

.m-12px { margin: 12px; }
.mt-12px { margin-top: 12px; }
.mr-12px { margin-right: 12px; }
.mb-12px { margin-bottom: 12px; }
.ml-12px { margin-left: 12px; }

.m-15px { margin: 15px; }
.mt-15px { margin-top: 15px; }
.mr-15px { margin-right: 15px; }
.mb-15px { margin-bottom: 15px; }
.ml-15px { margin-left: 15px; }
.mx-15px { margin-left: 15px; margin-right: 15px; }
.my-15px { margin-top: 15px; margin-bottom: 15px; }

.m-16px { margin: 16px; }
.mt-16px { margin-top: 16px; }
.mr-16px { margin-right: 16px; }
.mb-16px { margin-bottom: 16px; }
.ml-16px { margin-left: 16px; }
.mx-16px { margin-left: 16px; margin-right: 16px; }
.my-16px { margin-top: 16px; margin-bottom: 16px; }

.m-20px { margin: 20px; }
.mt-20px { margin-top: 20px; }
.mr-20px { margin-right: 20px; }
.mb-20px { margin-bottom: 20px; }
.ml-20px { margin-left: 20px; }
.mx-20px { margin-left: 20px; margin-right: 20px; }
.my-20px { margin-top: 20px; margin-bottom: 20px; }

.m-25px { margin: 25px; }
.mt-25px { margin-top: 25px; }
.mr-25px { margin-right: 25px; }
.mb-25px { margin-bottom: 25px; }
.ml-25px { margin-left: 25px; }
.mx-25px { margin-left: 25px; margin-right: 25px; }
.my-25px { margin-top: 25px; margin-bottom: 25px; }

.m-30px { margin: 30px; }
.mt-30px { margin-top: 30px; }
.mr-30px { margin-right: 30px; }
.mb-30px { margin-bottom: 30px; }
.ml-30px { margin-left: 30px; }
.mx-30px { margin-left: 30px; margin-right: 30px; }
.my-30px { margin-top: 30px; margin-bottom: 30px; }

.m-40px { margin: 40px; }
.mt-40px { margin-top: 40px; }
.mr-40px { margin-right: 40px; }
.mb-40px { margin-bottom: 40px; }
.ml-40px { margin-left: 40px; }
.mx-40px { margin-left: 40px; margin-right: 40px; }
.my-40px { margin-top: 40px; margin-bottom: 40px; }

.br-3px { border-radius: 3px; }
.br-4px { border-radius: 4px; }
.br-5px { border-radius: 5px; }
.br-6px { border-radius: 6px; }
.br-8px { border-radius: 8px; }
.br-10px { border-radius: 6px; }
.br-12px { border-radius: $feed-border-radius; }

.vh-100 { height: 100vh !important; }

.right-20px {
  position: absolute;
  right: 20px;
}

.position-fixed { position: fixed; }

i-feather[name="check-circle"] {
  svg {
    display: none;
  }
  background-image: url('/assets/img/verified-badge.svg');
  background-size: contain;
  position: relative;
  top: 3px;
}

.feather-xsmall {
  height: 14px !important;
  width: 14px !important;
}

.feather-small {
  height: 16px !important;
  width: 16px !important;
}

.feather-medium {
  height: 20px !important;
  width: 20px !important;
}

.feather-large {
  height: 24px !important;
  width: 24px !important;
}

.feather-xlarge {
  height: 34px !important;
  width: 34px !important;
}

.feather-xxlarge {
  height: 90px !important;
  width: 90px !important;
}

.limit-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.modal-backdrop.show {opacitY: 0.9 !important}

.global__container {
  min-height: 100vh;
  display: flex;
}

.global__import {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.global__iframe {
  height: 100vh;
  width: 100vw;
}

// first child is router-outlet
// last child is a Page component
.global__container > *:last-child {
  width: 100%;
}

.global__desktop {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  background: var(--tile-bg);
}

@media (min-width: 992px) {
  .global__desktop {
      display: flex;
  }

  .global__mobile {
      display: none !important;
  }
}

@media (max-width: 991.98px) {
  .global__desktop {
      display: none !important;
  }

  .global__mobile {
      display: flex;
  }
  feed {
    tab-selector {
      .tab-selector__container {
        position: fixed;
        background: var(--tile-bg);
        z-index: 1;
        border-bottom: 1px solid var(--border);
      }
    }
  }
  .mobile-scroll {
    // padding: 0px 8px;
  }
  browse-page {
    top-bar-mobile-header {
      .global__top-bar__height {
        border-bottom: none !important;
      }
    }
  }
  top-bar-mobile-navigation-control {
    margin-top: 8px;
  }
  top-bar-mobile-header {
    search-bar {
      .global__top-bar__height {
        margin-top: 0px !important;
      }
    }
  }
  post-thread-page {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .feed-post__container {
      border-radius: 0px 0px 0px 0px;
    }
  }
  mint-nft-page {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .nft-modal-container {
      border-radius: 0px;
    }
    .global__content__inner {
      background: var(--tile-bg);
    }
    .mint-nft__create-btn {
      width: calc(100vw - 32px);
      margin: 16px 16px 80px 16px;
    }
  }
  left-bar {
    jumio-status {
      .jumio-status {
        margin-top: 0px;
      }
    }
  }
  nft-post-page {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .feed-post__container {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .nft-auction-table-container {
      height: 356px;
      overflow-y: scroll;
    }
    .nft-post-auction-action {
      width: calc(50vw - 28px) !important;
    }
  }
  sell-nft-page {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .nft-modal-container {
      border-radius: 0px;
      margin-bottom: 45px
    }
    .global__content__inner {
      background: var(--tile-bg);
    }
    .mint-nft__create-btn {
      width: calc(100vw - 32px);
      margin: 0px;
    }
  }
  .modal-dialog-centered {
    margin: 1.75rem auto;
  }
  .modal-header {
    justify-content: center !important;
    .modal-header-x {
      right: auto !important;
    }
  }
  place-bid-modal {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .nft-modal-container {
      border-radius: 0px;
    }
    .floating-bottom-bar {
      justify-content: flex-start;
    }
    background: var(--tile-bg);
  }
  app-messages-page {
    .global__mobile-scrollable-section {
      padding-left: 0px;
    }
    .messages-thread-view__message-builder {
      position: fixed;
      top: auto;
      bottom: 0px;
      width: 100%;
    }
  }
  message-recipient-modal {
    #searchBarRoot {
      margin-top: 0px;
      .global__top-bar__height {
        margin-top: 0px;
      }
    }
    .message-recipient-modal__header {
      flex-flow: column;
      height: 114px;
      width: calc(100vw - 28px);
    }
    .search-bar {
      max-width: calc(100vw - 60px);
    }
    .search-bar__results-dropdown {
      top: 113px !important;
    }
  }
  app-notifications-page {
    #header-content-spacer {
      margin-top: 60px !important;
    }
    background: var(--tile-bg);
  }
  wallet {
    .creator-profile__ellipsis-restriction {
      max-width: 200px;
    }
    .wallet-column {
      position:relative;
      padding-right:15px;
      padding-left:15px;
    }
    .wallet-actions-container {
      background: var(--tile-bg);
      border-radius: 8px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      width: 225px;
    }
    .dropdown-menu-item {
      color: var(--text-secondary);
    }
  }
  creator-profile-details {
    tab-selector {
      .tab-selector__container {
        justify-content: center;
      }
    }
    .creator-profile-details__coin-info {
      height: 100px !important;
    }
  }
  creator-profile-nfts {
    .creator-profile-nfts__dropdown {
      max-width: calc(50vw - 50px);
    }
  }
  quote-reposts-page, likes-page, reposts-page, diamonds-page {
    #header-content-spacer {
      margin-top: 54px !important;
    }
    .likes-modal-container, .quote-reposts-modal-container, .reposts-modal-container, .diamonds-modal-container {
      border-radius: 0px;
    }
  }
  #header-content-spacer.logged-out {
    margin-top: 100px !important;
  }
  wallet-page, creator-profile-page, referrals {
    .global__content__inner {
      padding-left: 0;
      padding-right: 0;
    }

  }
}

.floating-bottom-bar {
  position: fixed;
  bottom: 0px;
  background: var(--tile-bg);
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wide {
  @media (min-width: 992px) {
    max-width: 775px !important;
  }
}

.modal-dialog-high {
  .modal-content {
    position: fixed;
    top: 100px;
    right: 14px !important;
    left: 14px !important;
  }
}

.modal-dialog-light {
  .modal-content {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }
}

.circle-div {
  height: 80px;
  width: 80px;
  background: var(--nft-section-bg);
  border-radius: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  i-feather {
    color: var(--highlight);
  }
}

.diamond-popover-container {
  max-width: 100% !important;
  width: 400px;
  margin-right: 10px;
  margin-left: 10px;
  border-top-color: var(--border);
  background-color: var(--formbg);
}

.diamond-popover-container .arrow::after, .diamond-popover-container .arrow::before {
  border-top-color: var(--formbg);
}

.diamond-popover-container-mobile {
  width: auto;
}

#diamond-popover {
  color: var(--text);
}

.fixed-top-bar {
  position: fixed;
  background-color: var(--background);
  z-index: 10
}

.global__mobile-scrollable-section {
  padding-left: 10px;
  flex-grow: 1;
  overflow-y: scroll;
}

// For the space covered by the bottom bar
.global__bottom-bar-mobile-height {
  height: 60px; // current height of the bottom bar
}

.global__bottom-bar-mobile {
  z-index: 2;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--background);
  height: 60px;
}

.pwa-bottom-bar-mobile {
  height: 80px !important;
  padding-bottom: 20px;
}

.global__tooltip-icon {
  cursor: pointer;
}

.global__mat-tooltip {
  cursor: pointer;
  background-color: #343a40 !important;
  white-space: pre-line;
}

.global__mat-tooltip-font-size {
  font-size: 15px !important;
}

input.bs-timepicker-field {
  font-size: 15px;
}

.btn-default {
  font-size: 15px;
  border-color: var(--border) !important;
}

.loading-container {
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  font-size: 17px;
  font-weight: 500;
}

app-landing-page {
  background: white;
}
@keyframes slideInFromTopRight {
  0% {
    transform: translate(-100px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(10deg);
  }
}

@keyframes slideInFromTopLeft {
  0% {
    transform: translate(100px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(-10deg);
  }
}

@keyframes blurRotate {
  0%   { transform:rotate(0deg); }
  100% { transform:rotate(45deg); }
}

@keyframes blurBreathe {
  0%   { transform:scale(.75); }
  100% { transform:scale(1.25); }
}

.landing-page-rotating-blur {
  animation: 12s ease 0s infinite blurRotate alternate, 4s ease 0s infinite blurBreathe alternate;
  @media (max-width: 992px) {
    top: -140px;
  }
}

@keyframes disappear {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes appear {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.disappear {
  animation: .5s ease 0s 1 disappear forwards;
}

.appear {
  animation: .5s ease 0s 1 appear forwards;
}

app-landing-page {
  .aos-init {
    z-index: 1;
  }
}

.landing-page-top-bar {
  margin: 0px auto 0px auto;
  z-index: 1;
  max-width: 1200px;
  padding: 24px;
}

.landing-page-footer-border{
  border-bottom: 1px solid #DFE3E9;
}

.landing-page-content-container {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
}

.landing-page-svg {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-page-container-bg {
  background: #000;
}

.landing-page-container-1 {
  height: 900px;
  background: #000;
  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
}

.landing-page-nft-card-holder {
  padding: 35px;
  width: 461.15px;
  position: absolute;
  border-radius: 30px;
  @media (max-width: 992px) {
    max-width: 60%;
    width: 300px;
    padding: 25px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .fc-landing-page {
    color:rgba(255, 255, 255, 0.4);
  }
}

.landing-page-diamond-square {
  left: 23px;
  @media (max-width: 992px) {
    width: 350px;
    height: 350px;
    left: auto;
    position: relative;
  }
}

.landing-page-diamond-device {
  left: 110px;
  @media (max-width: 992px) {
    left: auto;
    width: 253px;
    height: 513px;
  }
}

.landing-page-device {
  @media (max-width: 992px) {
    width: 308px;
    height: auto;
    right: 44px;
  }
}

.landing-page-nft-card-fs-small {
  font-size: 20px;
  @media (max-width: 992px) {
    font-size: 15px;
  }
  font-weight: 600;
}

.landing-page-nft-card-fs-title {
  color: white;
}

.landing-page-nft-card-fs-large {
  font-size: 26px;
  @media (max-width: 992px) {
    font-size: 20px;
  }
  font-weight: 600;
}

.landing-page-info-tile {
  padding: 20px 55px;
  @media (min-width: 992px) {
    width: 650px;
    height: 125.09px;
    padding: 0px 55px;
  }
  background: #F9FAFB;
  border-radius: 20px;
}

.landing-page-creator-coins-table {
  @media (min-width: 992px) {
    width: 1219.74px;
    height: 674.36px;
  }
  background: rgba(255, 255, 255, 0.52);
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  margin-top: 50px;
}

.landing-page-creator-coins-fade-out {
  position: absolute;
  width: 100%;
  height: 252px;
  bottom: 0px;
  border-radius: 0px 0px 30px 30px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 49.48%, #FFFFFF 100%);
  left: 0px;
}

.landing-page-creator-coin-explainer-container {
  margin-bottom: 25px;
  @media (min-width: 992px) {
    width: 388px;
    margin-bottom: 0px;
  }
  z-index: 1;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
}

.landing-page-creator-coins-explainer-header {
  width: 100%;
  margin-bottom: 30px;
}

.landing-page-explainer-image {
  filter: invert(1);
  width: auto;
  max-height: 180px;
}


.landing-page-explainer-image-small {
  filter: invert(1);
  width: auto;
  max-height: 120px;
}

.landing-page-creator-coins-explainer-section-title {
  color: white;
  margin-top: 110px;
  @media (min-width: 600px) {
    margin-top: 80px;
  }
}

.style-for-buy-eth-warning {
  @media (min-width: 600px) {
  }
  @media (max-width: 600px) {
    margin-top: 15px;
  }
}

.landing-page-creator-coins-explainer-title {
  color: white;
}

.landing-page-creator-coins-explainer-body {
  width: 100%;
  padding: 0px 35px;
}

.landing-page-profile-pic {
  width: 42.44px;
  height: 42.44px;
  border-radius: 6px;
}

.landing-page-row {
  padding: 20px 0px;
  border-top: 1px solid rgba(27, 28, 30, .1);
}

.landing-page-thumbs-up {
  position: relative;
  top: -17px;
  @media (max-width: 992px) {
    height: 50px;
    width: 50px;
    top: -10px
  }
}

.landing-page-robot-1 {
  animation: 1s ease 0.5s 1 slideInFromTopLeft forwards;
  transform: translate(100px, 0px) rotate(0deg);
  z-index: 1;
  right: 79px;
  filter: grayscale(100);
  top: 45.75px;
  opacity: 0.2;
  color: white;
  @media (max-width: 1150px) and (min-width: 992px) {
    right: -70px;
  }
  @media (max-width: 992px) {
    right: 36%;
    opacity: 0.4;
  }
}

.landing-page-robot-2 {
  border: 8px solid $landing-page-highlight;
  transform: translate(-100px, 0px) rotate(0deg);
  background: black;
  box-shadow: -20px 39px 74px rgba(0, 0, 0, 0.13);
  animation: 1s ease 0.5s 1 slideInFromTopRight forwards;
  z-index: 2;
  right: -125px;
  top: 85px;
  @media (max-width: 1150px) and (min-width: 992px) {
    right: -274px;
  }
  @media (max-width: 992px) {
    right: -31%;
    max-width: 40%;
    top: 40px;
  }
  backdrop-filter: blur(20px);
  position: relative;;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width:100%;
    background: linear-gradient(60deg, #ff8b17, #ff4c28, #ff034a, #7700ff, #5073b8, #00ddff, #00ffdd, #3dff6e);
    z-index: -1;
    animation: animatedgradient 8s ease alternate infinite;
    background-size: 300% 300%;
    border-radius: 20px;
  }
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 50% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.landing-page-header-main {
  font-size: 140px;
  line-height: 115px;
  font-family: "Druk";
  text-transform: uppercase;
  margin-top: -100px;
  letter-spacing: 2px;
  @media (max-width: 992px) {
    font-size: 80px;
    line-height: 70px;
    margin-top: 0px;
  }
  font-weight: 800;
  color: $landing-page-highlight;
}

.landing-section-header {
  font-size: 200px;
  line-height: 130px;
  font-family: "Druk";
  text-transform: uppercase;
  letter-spacing: 2px;
  @media (max-width: 992px) {
    font-size: 80px;
    line-height: 70px;
    margin-top: 0px;
  }
  font-weight: 800;
  color: $landing-page-highlight;
}



.landing-page-testimonial-quote {
  font-family: "Druk";
  color: #333;
  text-transform: uppercase;
  font-size: 102px;
  letter-spacing: 2px;
  width: 50%;
  line-height: 88px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-style: italic;
}

.landing-page-testimonial-person {
  width: 240px;
}

@media (max-width: 992px) {
  .landing-page-testimonial-quote {
    width: 90%;
    margin-top: 20px
  }
}


@media (max-width: 601px) {
  .landing-page-testimonial-quote {
    font-size: 54px;
    line-height: 50px;
    width: 80%;
    margin-top: 20px
  }
  .landing-page-testimonial-person {
    width: 65%;
    margin: 0 auto;
  }
  .landing-page-testimonial {
    margin-top: 250px !important;
  }
}


.landing-page-title {
  z-index: 1;
  font-weight: 400;
  font-size: 48px;
  line-height: 1em;
  @media (max-width: 992px) {
    font-size: 34px;
    line-height: 40px;
  }
  color: $landing-page-dark;
}

.landing-page-creator-coins-explainer-desc {
  color: #888;
}

.landing-page-subtext {
  font-size: 20px;
  line-height: 1.4em;
  font-weight: 400;
  color: #999;
}

.landing-page-content-text {
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: $landing-page-fc;
}

.landing-page-btn {
  transition: background 0.5s;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 262px;
  height: 88px;
  cursor: pointer;
  @media (max-width: 992px) {
    height: 68px;
    padding: 0px;
    width: 100%;
  }
  box-shadow: 0px 6px 14px rgba(27, 28, 30, 0.1);
  border-radius: 6px;
  border: none;
  font-size: 20px;
  line-height: 22px;
}

.landing-page-primary-btn {
  background: $landing-page-dark;
  color: white;
  &:hover {
    background:$landing-page-highlight;
    color: black;
    .landing-page-button-subtext { color: black }
  }
}

.landing-page-primary-btn-inverted {
  background: $landing-page-highlight;
  color: black;
  &:hover {
    background: darken($landing-page-highlight, 10%);
    color: black;
    .landing-page-button-subtext { color: black }
  }
}

.landing-page-secondary-btn {
  background: rgba(255, 255, 255, 0.4);
  color: $landing-page-dark;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
}

.landing-page-button-subtext {
  font-size: 14px;
  color: #999;
}

.landing-page-blob-1 {
  background: #4ACFFF;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(100px);
  width: 316.57px;
  height: 497.15px;
  top: -220px;
  right: 200px;
  @media (max-width: 992px) {
    left: -200px;
    top: -255px;
  }
  position: absolute;
  background-image: url('/assets/img/blob-1.svg');
}

.landing-page-blob-2 {
  width: 305.03px;
  height: 305.03px;
  @media (max-width: 992px) {
    top: 0px;
  }
  background: #FF3333;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(200px);
  top: 150px;
  right: -350px;
  position: absolute;
}

.landing-page-blob-3 {
  width: 769.39px;
  height: 769.39px;
  top: -400px;
  right: -350px;
  @media (max-width: 992px) {
    top: -600px;
  }
  position: absolute;
  background: linear-gradient(180deg, #FFD8FC 0%, #FF41F2 100%);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(203.509px);
}

.landing-page-blob-4 {
  width: 270.9px;
  height: 400px;
  left: -130px;
  top: -200px;
  background: #4ACFFF;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(125px);
  position: absolute;
}

.landing-page-blob-5 {
  width: 400px;
  height: 400px;
  left: -75pxpx;
  top: -187px;
  background: linear-gradient(180deg, #FFD8FC 0%, #FF41F2 100%);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(197.375px);
}

.landing-page-blob-6 {
  width: 198.36px;
  height: 198.36px;
  left: 120px;
  top: 200px;
  background: #FF3333;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  filter: blur(150px);
}

.landing-page-row-3-inner-box {
  width: 338px;
  height: 338px;
  background: $landing-page-dark;
  border-radius: 27px;
}

.landing-page-row-3-box {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: -20px 39px 74px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 30px;
  z-index: 2;
  position: relative;
  padding: 45px;
  width: 425.13px;
  height: 506.44px;
  @media (max-width: 992px) {
    width: 232.43px;
    height: 276.89px;
    padding: 24px;
    left: 24%;
    .landing-page-row-3-inner-box {
      width: 184.8px;
      height: 184.8px;
      img {
        width: 184.8px;
        height: 184.8px;
      }
    }
    button {
      width: 184.8px !important;
      height: 39.37px !important;
      font-size: 11px;
    }
  }
}

@media (max-width: 992px) {
  .landing-page-network-top {
    width: 247px !important;
    height: 80px !important;
    top: -60px !important;
    right: 80px !important;
  }
  .landing-page-network-bottom {
    width: 247px !important;
    height: 80px !important;
    bottom: 163px !important;
    left: 88px !important;
  }
  .landing-page-network-left {
    width: 120px !important;
    height: 206px !important;
    top: 15px !important;
    left: 0px !important;
  }
  .landing-page-network-right {
    width: 120px !important;
    height: 206px !important;
    top: 15px !important;
    right: 0px !important;
  }
  .landing-page-network-bulb {
    display: none;
  }
}

.landing-page-build-on-deso-container {
  backdrop-filter: blur(20px);
  padding: 70px 24px 70px 24px;
  @media (min-width: 992px) {
    width: 1012px;
    height: 598.48px;
    padding: 90px 170px 77px 170px;
  }
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 30px;
}

//
// Top-level css for the feed.
//

// This removes the scrollbar on the feed.
.disable-scrollbars::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.light-grey-divider {
  background-color: var(--background-secondary);
}

.top-bar-mobile__avatar {
  height: 40px;
  width: 40px;
  border-radius: 3px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.top-bar-mobile-navigation-control__dark-color {
  color: #8A8A8A;
}

.top-bar-mobile-navigation-control__light-color {
  color: white;
}

.bottom-bar-mobile__holder-div {
  width: 100%;
  height: 100%;
}

.bottom-bar__message-notifications-bubble {
  position:absolute;
  top: 10px;
  width: 12px;
  height: 12px;
  padding-top: 1px;
  font-style: normal;
  margin-left: 15px;
  font-size: 12px;
  border-radius: 100%;
  background: var(--error);
  color: var(--text-inverted)
}

// flex -> width -> fixed -> inner
// content can fit center + sidebar

.global__nav__flex {
  flex-grow: 1;
  align-items: flex-end;
  flex-direction: column;
  background: var(--tile-bg);
  display: flex;
}

.global__nav__width {
  width: $global__nav__width;
  background: var(--tile-bg);
  @media (max-width: 992px) {
    width: 100%;
  }
}

.global__nav__fixed {
  top: 64px;
  border-right: 1px solid var(--border);
  background: var(--tile-bg);
  @media (max-width: 992px) {
    top: 42px;
  }
  height: 100%;
  position: fixed;
}

.global__nav__inner {
  width: $global__nav__width;
  overflow-y: auto;
  height: 100%;
  @media (max-width: 992px) {
    height: calc(100vh - 42px);
    width: 75vw;
    max-width: 280px;
    min-width: 245px;
  }
  padding: 0 20px;
}

.global__content__flex {
  @media (max-width: 992px) {
    margin-top: 0px;
  }
  flex-grow: 1;
  background: var(--tile-bg);
  align-items: flex-start;
  flex-shrink: 1;
  display: flex;
}

// Set a fixed width on desktop
.global__desktop .global__content__width {
  width: $global__content__width;
}

// Set a relative width on mobile
.global__mobile .global__content__width {
  width: 100vw;
}

// Set a width, height, and border on desktop
.global__desktop .global__content__inner {
  min-height: 100vh;
  width: $global__content__width;
  border-left: var(--border) solid 1px;
  border-right: var(--border) solid 1px;
}

.global__sidebar__width {
  width: $global__sidebar__width;
}

.global__sidebar__fixed {
  top: 64px;
  height: calc(100% - 64px);
  padding: 20px 0 20px 20px;
  position: fixed;
  overflow-y: scroll;
  border-left: 1px solid var(--border);
}

.global__sidebar__inner {
  width: $global__sidebar__width;
}

.global__center__width {
  width: $global__center__width;
}

browse-page {
  .global__center__inner {
    margin-top: 0px;
  }
}

.spinner-color {
  color: green;
  stroke: green;
  svg {
    stroke: green;
    color: green;
    path {
      stroke: green;
      color: green;
    }
  }
}

.global__center__inner {
  min-height: 100vh;
  width: $global__center__width;
  @media (min-width: 992px) {
    margin-top: 4px;
  }
}

.global__top-bar {
  position: fixed;
  background: var(--background);
  z-index: 30;
  width: $global__center__width - 2; // subtract 2 for borders

  @media (min-width: 992px) {
    height: $global__top-bar__desktop-height;
  }

  @media (max-width: 991.98px) {
    width: 100%;
    height: $global__top-bar__height;
  }
}

.error-container {
  background: var(--error-light);
  color: var(--error);
  height: 48px;
  font-size: 15px;
  line-height: 24px;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
}

feed-create-post {
  background: var(--tile-bg);
  textarea {
    &.background-color-white {
      background: var(--tile-bg) !important;
    }
  }

  .menu {
    background-color: var(--tile-bg);
    position: absolute;
    z-index: 3;
    border-radius: 6px;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 10%);
    :first-child {
      border-radius: 10px 10px 0px 0px;
    }
    :last-child {
      border-radius: 0px 0px 10px 10px;
    }
    :first-child:last-child {
      border-radius: 10px !important;
    }
  }

  .menu-item {
    cursor: default;
    padding: 1rem;
    color: var(--text-muted);
  }

  .menu-item.selected {
    background-color: var(--tile-secondary-bg);
    color: var(--text);
  }

  .menu-item:hover:not(.selected) {
    background-color: var(--tile-bg);
  }
// End weird hoverfill stuff

}

nft-post {
  .nft-post-details {
    background: var(--tile-bg);
    margin-top: 20px;
    border-radius: 0;
    border: 1px solid var(--border);
    border-left: 0;
    border-right: 0;
  }
  post-thread {
    .post-thread__container {
      border: none;
    }
    .post-thread__comment-container {
      border-top: none;
      margin-top: 0px;
      .feed-post__container {
        border-radius: 0px;
      }
    }
  }
}

tab-selector {
  .tab-selector-links {
    border-bottom: 1px solid var(--border);
    .active-tab {
      color: var(--highlight);
      border-bottom: 1.5px solid var(--highlight);
      &.first-tab {
        padding-left: 0px;
      }
    }
  }
  button {
    background: var(--tile-bg);
    height: 40px;
    color: var(--highlight);
    position: relative;
    border: 0;
    padding: 0;
    font-size: 15px;
  }
  .new-tab-indicator {
    background: var(--tile-bg);
    color: var(--highlight);
    position: relative;
    top: -1px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    span {
      position: relative;
      font-size: 13px;
      font-weight: normal;
    }
  }
}

.global__top-bar__height {
  @media (min-width: 992px) {
    height: $global__top-bar__desktop-height;
  }

  @media (max-width: 991.98px) {
    height: $global__top-bar__height;
  }
}

.left-bar-more-container {
  background: var(--tile-bg);
  bottom: 0px;
  border-radius: 6px;
  border: 1px solid var(--text);
  box-shadow: 6px 6px var(--text);
  padding: 12px 3px;
}

.left-bar-close-button {
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    color: var(--highlight);
  }
}


.left-bar-mobile__translucent-background {
  z-index: 99 !important; // Needs to be higher than the bottom bar and staked posts.
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
}

.left-bar-mobile__flyout-div {
  z-index: 100; // Needs to be higher than the bottom bar and staked posts.
  position: fixed;
  top: 0;
  width: 75vw;
  max-width: 280px;
  min-width: 245px;
  height: 100vh;
  background-color: var(--tile-bg);
}

.left-bar-mobile__deso-price {
  background: var(--tile-secondary-bg);
  padding: 7px 8px 7px;
  border-radius: 6px;
  border: 1px solid var(--border);
}

left-bar-mobile {
  .left-desktop-selector {
    background: none;
    border: none;
    padding: 0px;
  }
  .global__nav__inner {
    padding: 0px;
  }
  jumio-status {
    .jumio-status {
      background: var(--tile-bg);
      padding: 7px 8px 7px;
      border-radius: 6px;
      border: 1px solid var(--border);
    }
  }
}

.right-bar-select-box {
  border: none;
  background: none;
  outline: none;
  border-bottom: solid 1px;
}

.update-profile__image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 100px;
  width: 100px;
  background-color: transparent;
  position: relative;
  border-radius: 6px;
}

.update-profile__image-delete {
  width: 36px;
  height: 36px;
  position: absolute;
  right: -36px;
  top: -14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--error);
  cursor: pointer;
  font-weight: bold;
  font-size: 1.25rem;
  vertical-align: middle;
  padding-bottom: .15rem;
  i-feather {
    margin-top: -5px;
    height: 20px;
    width: 20px;
  }
}

feed-create-post {
  .update-profile__image-delete {
    border-radius: 6px;
  }
}
.holdings__creator-coin-name {
  @media (max-width: 992px) {
    padding-top: 15px;
  }

  @media (min-width: 993px) {
    padding-top: 0px;
  }
}

.holdings__creator-coin-total {
  @media (max-width: 992px) {
    padding-top: 15px;
  }

  @media (min-width: 993px) {
    padding-top: 20px;
  }
}

.holdings__divider {
  height: 66px;
  background-color: var(--background-secondary);
}

.holdings__avatar {
  height: 50px;
  width: 50px;
  min-width: 28px;
  margin-right: 15px;
  border-radius: 100px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.holdings__name {
  max-width: 200px;
}

.holdings__pub-key {
  overflow: hidden;

  overflow-wrap: anywhere;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  cursor: pointer;
}

.holdings__copy:active {
  color: $buy-button-blue;
}

.update-profile__pub-key {
  padding-left: 0;
  overflow: hidden;
  cursor: pointer;

  overflow-wrap: anywhere;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.update-profile__copy:active {
  color: $buy-button-blue;
}

.sign-up__grey-background {
  background-color: $light-grey-for-divider;
}

.sign-up__cp-button-override {
  background-color: white;
}

.sign-up__advanced {
  cursor: pointer;
}

.sign-up__advanced:hover {
  font-weight: bold;
}

.sign-up__phone-input {
  font-size: 15px !important;
}

// Overrides for phone number input defaults.
.iti {
	width: 100% !important;
}

.iti__country {
  font-size: 15px;
}

#country-search-box {
  font-size: 15px !important;
}

.onboarding-info-bar-circle {
  width: 40px;
  height: 40px;
  border: 3px solid var(--text);
  border-radius: 100%;
  font-size: 18px;
}

.onboarding-follow-selection {
  max-height: calc(100vh - 400px);
  overflow-y: scroll;
}

sign-up {
  .global__center__inner {
    width: 800px;
  }
  .global__sidebar__inner {
    display: none;
  }
  .desktop-top-bar {
    border: none;
    border-bottom: 1px solid #444;
  }
}

.feed-create-post__avatar.change-account-selector__account-image {
	width: 24px;
	height: 24px;
	min-width: 24px;
	margin-right: 10px;
	border-radius: 3px;
}

.change-account-selector {
	position: relative;
	z-index: 30;
	background-color: var(--tile-bg);
	width: 100%;
	padding-top: 8px;
	padding-bottom: 8px;
  border-radius: 6px;
	border: 1px solid var(--borderheavy);
  font-size: 15px;

}

.change-account-selector_list__inner {
	border-bottom: 1px solid var(--border);
}

.change-account-selector__down-arrow {
  margin-top: 2px;
  margin-left: 2px;
}

.change-account-selector__hover:hover {
	box-shadow: 1px 1px 2px rgba(101, 119, 134, 0.10);
}

.change-account-selector_list {
	background-color: var(--tile-bg);
	padding: 0px 0px 10px;
	margin-top: 3px;
	width: 100%;
	z-index: 99;
	border: 1px solid var(--text);
  font-size: 15px;
  border-radius: 8px;
  box-shadow: 6px 6px var(--text);
}

.change-account-selector_list_accounts {
  max-height: 35vh;
  overflow-y: scroll;
}

.change-account-selector_list-item{
  overflow: hidden;
}

.change-account-selector_list__inner:hover{
  background-color:var(--tile-secondary-bg);
  border-radius: 6px 6px 0 0;
}

.change-account-selector__shadow {
  box-shadow: rgba(101, 119, 134, 0) 0px 0px 15px, rgba(101, 119, 134, 0) 0px 0px 3px 1px;
}

.change-account-selector__ellipsis-restriction {
  max-width: 150px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.change-account-selector__trash {
  opacity: 0;
}

.text-link_hover:hover{
  color:var(--hover);
}

.tutorial-skip-button {
  height: 30px;
  width: 60px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background: var(--tile-bg);
  color: var(--text-secondary);
  &:hover {
    color: var(--text-secondary);
  }
  &.disabled-button {
    background: var(--tile-bg)
  }
  margin-top: 0px;
  margin-left: 10px;
}

.tutorial-header {
  padding: 16px;
  background: var(--background-secondary);
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.tutorial-header-step {
  font-weight: normal;
  font-size: 14px;
  color: var(--text-muted);
}

.tutorial-title {
  font-size: 20px;
  color: var(--text);
  line-height: 28px;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.tutorial-bottom-card {
  border-radius: 0px 0px 10px 10px;
}

.search-bar {
  border-radius: 6px;
  color: var(--text-muted);
  i-feather {
    height: 16px;
    width: 16px;
    color: var(--text);
    margin: 0px 12px;
    svg {
      position: relative;
      top: -7px;
    }
  }
  input {
    background: var(--tile-secondary-bg) !important;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.search-bar__icon {
  padding: 2px;
  padding-left: 5px;
  font-size: 19px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--tile-secondary-bg);
  border: none;
}

.search-bar__results-dropdown {
  margin-top: 10px;
  box-shadow: rgba(101, 119, 134, 0.2) 0px 10px 15px, rgba(101, 119, 134, 0.15) 0px 10px 3px 1px;
  border-radius: 0px 0px 16px 16px;
  cursor: pointer;
  background: var(--background);
  position: relative;
  top: -10px;
  border: solid 1px var(--border);
  z-index: 5; // the input has a z-index of 3 at one point.
  max-height: 600px;
  overflow-y: scroll;
}

.search-bar__avatar {
  height: 35px;
  width: 35px;
  border-radius: 3px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.search-bar__selected-color {
  background-color: var(--border);
}

.search-bar__fix-active {
  border-color: var(--background-secondary);
  border-left: 0px;
}

.search-bar__fix-active:active, .search-bar__fix-active:focus {
  border-color: var(--background-secondary);
  border-left: 0px;
}

.post-thread__comment-container {
  .post-thread__single-comment {
    background: var(--tile-bg);
    border-top: 1px solid var(--border);
  }
  .feed-post-container {
    background: var(--tile-bg);
    margin-bottom: 0px;
    border-bottom: none;
    border-top: none;
  }
  .feed-post__container {
    border-radius: 0px;
  }
  flex-grow: 1;
}

.pinned-post {
  border-left: 3px solid var(--highlight) !important;
  &.border {
    border-left: 3px solid var(--highlight) !important;
  }
}

.post-thread__container {
  background: var(--tile-bg);
  .feed-post-container {
    margin-bottom: 0px;
  }
  .post-thread__header {
    color: var(--text);
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    padding: 16px;
    i-feather {
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-right: 12px;
    }
  }
}

.interaction-modal__header {
  height: 25px;
}

.interaction-modal__close-button {
  margin: 0;
  padding: 0;
  float: none;
}

.feed-create-comment-form__reply-container {
  margin-left: 80px
}

.feed-create-comment-form__avatar {
  height: 50px;
  width: 56px; // TODO: figure out why chrome doesn't render this as 50px
  margin-right: 15px;
  border-radius: $feed-border-radius;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}



.feed-create-post__avatar {
  height: 50px;
  width: 50px;
  margin-right: 15px;
  border-radius: $feed-border-radius;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.nft__avatar {
    border: 0px;
    border-radius: 0px;
    mask-image: url('/assets/img/rounded-hex.svg');
    mask-size: 100%;
  }
}

.modal-open {
  padding-right: 0px !important;
}

.modal-header .feed-create-comment-form__close {
  margin: 0;
  padding: 0;
  float: none;
  color: var(--text);
  text-shadow: 0 1px 0 #fff;
}

.jumio-status {
  margin-bottom: 20px;
  padding: 16px 20px;
  border: 1px solid var(--border);
  border-radius: 0;
  background: var(--tile-bg);
  color: var(--text);
  font-size: 15px;
  line-height: 24px;
  span {
    margin: 0px 8px;
  }
}

trends {
  .icon-diamond {
    margin-left: 0px !important;
  }
}

.trends-scroll-container {
  height: calc(100vh - 210px) !important;
}

.trend-box {
  background: var(--tile-secondary-bg);
  border: 1px solid transparent;
  border-radius: 6px;
}

.trend-title {
  margin: 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: var(--text);
}

.trend-selector {
  position: absolute;
  right: 16px;
  .feed-post__dropdown-menu-item-repost {
    left: -190px !important;
  }
}

.trend-entry {
  a {
    border-top: 1px solid var(--border);
    .trend-holder {
      margin: 12px 16px;
    }
  }
  follow-button {
    font-size: 12px;
    line-height: 20px;
    color: var(--text-secondary);
    a {
      border: none;
    }
  }
}

.trend-name {
  color: var(--text);
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-top: 2px;
  i-feather {
    height: 16px;
    width: 16px;
  }
}

.trend-end {
  position: absolute;
  right: 16px;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.feed-create-post__avatar_default {
  background-image: url('/assets/img/default_profile_pic.png');
}

.feed-create-comment__textarea {
  min-height: 55px;
}

.modal-dialog .feed-create-post__textarea {
  min-height: 250px;
}

.feed-create-post__textarea:focus {
  border: none;
  box-shadow: none;
}

.feed-post-container {
  margin-bottom: 0;
  border-radius: 0;
  background: var(--tile-bg);
  border: 1px solid var(--border);
  border-right: 0;
  border-bottom: 0;
}

.js-feed-post-hover:hover {
  background-color: var(--background-secondary);
}

.feed-post__quoted-content {
  .feed-post__container {
    border: 1px solid var(--border);
    margin-top: 15px;
  }
  .feed-post__nft-footer {
    border: 1px solid var(--border);
    border-top: none;
  }
}

.feed-post__quoted-content .feed-post__container:hover {
  box-shadow: 0 0 10px var(--border) !important;
}

.feed-post__nft-list-view-unlockable {
  color: var(--text-muted);
  font-size: 15px;
  i-feather {
    margin: 0px 8px;
    width: 11.33px;
    height: 15.33px;
  }
}

bs-dropdown-container {
  z-index: 3000 !important;
}

.bc-dropdown-menu {
  background: var(--tile-bg);
  border-radius: 6px;
  border: none;
  box-shadow: 6px 6px 0px var(--text);
  border: 1px solid var(--text);
  div {
    margin: 6px;
  }
}

.feed-post__dropdown-menu-item {
  color: var(--text-muted);
  padding: 10px;
  padding-bottom: 0;
  font-size: 14px !important;
  &:last-of-type {
    padding-bottom: 10px;
  }
  &:hover {
    color: var(--text);
  }
  i-feather {
    height: 14px;
    width: 14px;
  }
}

.feed-post__dropdown-menu-item-repost {
  background-color: var(--tile-bg);
  border: 1px solid var(--text);
  border-radius: 6px;
  box-shadow: 6px 6px 0px var(--text);
  padding: 0px;
  div {
    margin: 11px;
  }
}

.feed-post__embed-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 700px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 5px;
  //min-height: 300px;
  @media (max-width: 992px) {

  }
}

.feed-post__embed-container-spacer {
  min-height: 315px;
}

.feed-post__image-container-spacer {
  min-height: 350px;
  @media (max-width: 992px) {
    min-height: 250px;
  }
}

.feed-post__image-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 450px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.feed-post__image {
  flex-shrink: 0;
  width: 100%;
}

.feed-post__video-container {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.feed-post__video {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.feed-post__blue-border {
  border-left: 5px solid $buy-button-blue;
}

.feed-post__image-delete {
  position: absolute;
  top: 9px;
  left: 9px;
  cursor: pointer;
  color: white;
  font-size: 19px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.feed-post-icon-row__icon {
  border-radius: 50%;
  margin-right: 4px;
  width: 30px !important;
  height: 30px !important;

  svg {
    margin-top: 5px;
    margin-left: 5px;
    width: 20px !important;
    height: 20px !important;

  }
  &:hover {
    background-color: var(--background-secondary);
  }
  &.fa-spinner {
    svg {
      display: none;
    }
  }
}

.feed-referral-header {
  countdown-timer {
    div {
      background: var(--tile-bg);
      border: none !important;
      font-size: 16px;
    }
  }
}

.feed-post-icon-row__icon-repost-option {
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;

}

.ptr--text, .ptr--icon{
  color: var(--text) !important;
}

.feed-post-icon-row__icon-repost-option-text {
  color: var(--text-muted);
  &:hover {
    color: var(--text);
  }
  font-weight: normal;
  font-size: 15px;
  line-hieght: 24px;
}

.feed-post-icon-row__icon.reply-icon {
  margin-left: -6px;
  &:hover {
    background-color: $comment-hover;
    color: $comment-color;
    transition: background-color .5s;
  }
}

/* repost hover animation */
.feed-post-icon-row__icon.repost-icon {
  &:hover {
    background-color: $repost-hover;
    color: $repost-color;
    transition: background-color .5s;
  }
}

/* like button hover animation */
.feed-post-icon-row__icon.heart-icon, .feed-post-icon-row__icon.heart-icon-fill{
  &:hover{
    background-color: $like-hover;
    color: $like-color;
    transition: background-color .5s;
  }
}

.feed-post-icon-row__icon.icon-heart-fill{
  svg {
    fill: currentColor;
  }
}

/* diamond button hover animation */
.feed-post-icon-row__icon.diamond-icon{
  &:hover{
    background-color: $diamond-hover;
    color: $diamond-color;
  }
}

/* copy link button hover animation */
.feed-post-icon-row__icon.icon-link{
  &:hover{
    background-color: $link-btn-hover;
    color: $link-btn-color;

  }
}

.feed-post__icon-row-holder {
}

.desktop-top-bar-width {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.desktop-top-bar {
  border-bottom: 1px solid var(--border);
  z-index: 1000;
  position: fixed;
  height: 64px;
  background: var(--tile-bg);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bitclout-logo{
    svg {
      width: 16.93px;
      height: 24px;
    }
    span {
      top: 5px;
      position: relative;
      color: var(--text-secondary);
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -.012em;
    }
  }
  search-bar {
    position: relative;
    width: 343px;
    .input-group-prepend {
      position: relative;
      top: 1px;
    }
    .search-bar,input {
      height: 44px;
      top: 1px;
      i-feather {
        margin: 0px 12px;
      }
    }
  }
  change-account-selector {
    position: relative;
    width: 164px;
  }
}

.desktop-create-post{
  background: var(--tile-bg);
  border-radius: 0;
  border: 1px solid var(--border);
  border-top: none;
  border-right: 0;
}

.feed-desktop-center-banner {
  background-color: var(--tile-bg);
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid var(--border);
  margin-top: 5px;
  padding: 10px;
  color: var(--highlight);
  display: flex;
  align-items: center;
  countdown-timer {
    div {
      background: var(--tile-bg);
      border: none !important;
      font-size: 16px;
    }
  }
  @media (max-width: 992px) {
    margin-top: 15px;
  }
}

feed {
  .jumio-status {
    border-radius: 0;
    padding: 10px;
    position: relative;
    margin-top: 0px;
    border-top: 0;
    margin-bottom: 5px;
    border-right: 0;
  }
}

.feed-post__container {
  background: var(--tile-bg);
}

.feed-post__container.feed-post__parent-post-font-size {
  font-size: 17px !important;
}

.feed-post__coin-price-holder {
  @media (max-width: 992px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
  }
  margin-left: 8px;
  height: 24px;
  padding: 2px 8px;
  border-radius: 6px;
  background-color: var(--tile-secondary-bg);
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: var(--text);
}

.feed-post__coin-price {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--text);
}

.feed-post__avatar {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 600px) {
    height: 36px;
    width: 36px;
  }
  &.nft__avatar {
    border: 0px;
    border-radius: 0px;
    mask-image: url('/assets/img/rounded-hex.svg');
    mask-size: 100%;
  }

}

.feed-post__username {
  font-size: 15px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--text);
  font-feature-settings: 'ss10' on, 'ss08' on;
}

.feed-post__avatar-container {
  width: 56px; // TODO: figure out why chrome doesn't render this as 50px
  height: 56px;
  margin-right: 15px;
  @media (max-width: 600px) {
    height: 36px;
    width: 36px;
    margin-right: 10px;
  }
}

.feed-post__follow-btn {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

#diamond-container{
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  backface-bisibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  svg {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    backface-bisibility: hidden;
    perspective: 1000;
    transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }
}

.feed-post__content {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-top: 8px;
  overflow-wrap: anywhere;
  word-break: break-word;
  outline: none;
}

.js-feed-post-icon-row__container{
  color: var(--text-muted);
  span {
    font-size: 13px;
    line-height: 20px;
  }
}

.feed-post__nft-footer {
  padding: 16px;
  background: var(--nft-section-bg);
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: var(--text);
  border-radius: 0px 0px 0 0;
}

.nft-modal__header {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--text);
}

.nft-modal__subtitle {
  font-weight: 400;
  line-height: 24px;
  font-size: 15px;
  color: var(--text-muted);
}

.nft-content {
  margin: 0px 0px;
  position: relative;
}

.nft-top-row {
  width: 284px;
  position: relative;
  display: flex;
}

.nft-unlockable-content {
  display: inline;
  margin-left: 16px;
  color: var(--text-inverted-muted);
  svg {
    height: 20px;
    width: 20px;
  }
}

.nft-bid-btn {
  button {
    color: var(--tile-bg);
    width: 118px;
    border-radius: 6px;
    background: var(--highlight);
    margin: 0px 0px;
    padding: 6px 12px;
    font-style: normal;
    font-width: normal;
    font-size: 13px;
    line-height: 20px;
    border: none;
    height: 44px !important;
    span {
      top: 1px;
      i-feather {
        height: 20px;
        width: 20px;
      }
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

feed-post-dropdown {
  position: absolute;
  right: 0px;
}

.left-bar-active-indicator {
  width: 0px;
  border-radius: 6px;
}

.left-bar-button {
  height: 40px;
  font-weight: 600;
  font-size: 17px;
  color: var(--text-secondary);
  &:hover {
    color: var(--highlight);
  }
  a {
    color: var(--text);
    &:hover {
      color: var(--highlight);
    }
  }
  i-feather {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
  }
}

.left-bar-link {
  &:hover {
    color: var(--highlight) !important;
    a {
      color: var(--highlight) !important;
    }
  }
}

.left-bar__post-button {
  width: 80%;
  height: 48px;
  background: var(--text);
  border-radius: 6px;
  padding: 12px 24px;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  border: none;
  margin-top: 10px;
  color: var(--tile-bg);
}

.left-bar-active {
  a {
    color: var(--highlight);
  }
  i-feather {
    color: var(--highlight);
  }
}

.left-bar__dot-active {
  border-left: 2px solid var(--highlight);
  border-end-end-radius: 10px;
}

.left-bar__dot-unread {
  font-size: 50px;
  line-height: 18px;
  color: $red-font-color !important;
}

.left-bar__dot-inactive {
  font-size: 50px;
  line-height: 18px;
  color: rgba(0,0,0,0);
}

.left-desktop-selector {
  // border: 1px solid var(--border);
}

right-bar-creators {
  .global__sidebar__inner {
    z-index: 0;
  }
}

.right-bar-creators__wallet-container {
  background: var(--tile-secondary-bg);
  padding: 16px;
  font-size: 15px;
  color: var(--text-secondary);
  border-radius: 6px;
  margin-bottom: 20px;
}

.right-bar-creators-leaderboard__creator-avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.right-bar-creators__balance-box {
  background-color: var(--tile-secondary-bg);
  color: var(--text-secondary);
  font-size: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  .deso-metrics {
    margin: 16px;
  }
  .divider {
    width: 100%;
  }
  .buy-deso {
    font-weight: 600;
    display: none;
    font-size: 17px;
    color: var(--highlight);
    margin-top: 5px;
    margin: 12px 0px;
  }
}


.creator-leaderboard__load-more {
  background-color: var(--background-secondary);
}

.creator-leaderboard__load-more:hover {
  background-color: var(--nft-section-bg);
}

.feed__light-grey-divider {
  width: 100%;
  min-height: 10px;
  border-bottom: var(--border) 1px solid;
  background-color: $light-grey-for-divider;
}

.creator-profile__top-bar {
  background-color: var(--topbar);
  @media (max-width: 992px) {
    height: 100px;
    min-height: 100px;
  }

  @media (min-width: 993px) {
    height: 80px;
    min-height: 80px;
  }
}

.creator-profile__bid-details {
  font-size: 15px;
  color: var(--text-muted);
  padding: 0px 16px 8px 16px;
  background: var(--tile-bg);
  border: 1px solid var(--border);
  border-top: none;
  i-feather {
    position: relative;
    top: -1px;
  }
}

.creator-profile-nfts__bid-card {
  .creator-profile-nfts__list-container {
    border-bottom: none;
  }
}

.creator-profile-top-card__edit-btn {
  color: var(--highlight);
}

.creator-profile-top-card__buy-btn {
  width: 78px;
  height: 48px;
  background: var(--highlight);
  color: var(--tile-bg);
  margin-left: 24px;
  border-radius: 6px;
  padding-top: 12px;
  &:hover {
    color: var(--tile-bg);
  }
}

.creator-profile__avatar-border.nft__avatar {
  height: 78px;
  width: 78px;
  background: var(--text);
  position: absolute;
  left: -3px;
  top: -3px;
  border: 0px;
  border-radius: 0px;
  mask-image: url('/assets/img/rounded-hex.svg');
  mask-size: 100%;
  z-index: 0;
}

.creator-profile__avatar {
  position: relative;
  height: 72px;
  width: 72px;
  border-radius: 100px;
  border: 3px solid var(--text);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &.nft__avatar {
    z-index: 1;
    border: 0px;
    border-radius: 0px;
    mask-image: url('/assets/img/rounded-hex.svg');
    mask-size: 100%;
  }
}

.creator-profile__username-price {
  margin-left: 20px;
  margin-top: 8px;
}

.creator-profile__update-buy {
  position: absolute;
  right: 0px;
  top: 10px;
  i-feather {
    height: 16px;
    width: 16px;

  }
}

.creator-profile-details__no-results {
  background: var(--tile-bg);
  padding: 70px 0px 104px 0px;
  border-radius: 0px 0px 10px 10px;
}

.creator-profile-details__hodler-avatar {
  display: inline-block;
  height: 30px;
  width: 30px;
  padding-right: 5px;
  border-radius: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.creator-profile__reserved-tag {
  font-weight: normal;
  background-color: #f3f3f3;
  border-radius: 6px;
  height: fit-content;
}

.creator-profile__ellipsis-restriction {
  max-width: 150px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.creator-profile__ellipsis-restriction-full {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.creator-profile__divider-border {
  width: 100%;
  border-top: 1px solid var(--text-inverted);
  margin-bottom: 20px;
  margin-top: 5px;
}

.creator-profile__public-key {
  margin-bottom: 16px;
  i-feather {
    &.copy-button, &.check-indicator {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
    &.check-indicator{
      color: var(--highlight);
    }
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

.update-profile__avatar{
  display: flex;
  justify-content: center;
  background: var(--text);
  padding: 24px;
}

.update-profile__text {
  background: var(--tile-bg);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.update-profile__title {
  color: var(--text-muted);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 8px;
  i-feather {
    height: 16px !important;
    width: 16px !important;
    color: var(--text-muted);
    cursor: pointer;
  }
}

.update-profile__update-btn {
  padding-top: 10px;
  height: 48px;
  width: 100%;
  border-radius: 6px;
  color: var(--tile-bg);
  background: var(--highlight);
  font-size: 17px;
  font-weight: 600;
  &:hover {
    color: var(--tile-bg);
  }
}

wallet-page {
  .global__content__inner {
    border: none !important;
  }
}

.wallet__container {
  background: var(--tile-bg);
  border-radius: 0;
  border: 1px solid var(--border);
  border-top: 0;
  @media (min-width: 992px) {
    margin-top: 4px;
  }
}

.wallet__table {
  background: var(--tile-bg);
  border: 1px solid var(--border);
  border-top: 0;
  border-radius: 0x;
}

buy-creator-coins-tutorial-page, wallet-tutorial-page {
  .global__center__inner {
    min-height: auto;
  }
  .global__container {
    min-height: auto;
  }
}

wallet-tutorial-page {
  .global__mobile-scrollable-section {
    padding-left: 0px;
  }
  .wallet-highlighted-creator {
    border: 1px solid var(--highlight) !important;
    border-radius: 6px;
  }
  .wallet__table {
    margin-top: 0px;
    tab-selector {
      .tab-selector__container {
        background: var(--background-secondary);
      }
      .active-tab {
        color: var(--text-secondary);
        font-weight: 600;
        border: none;
      }
    }
  }
}

.wiggle {
  animation: shake 3s cubic-bezier(.36,.07,.19,.97) both infinite;
  animation-delay: 5s;
  transform: rotate(0deg);
  perspective: 1000px;
}

@keyframes shake {
  2.5%, 27.5% {
    transform: rotate(0deg);
  }

  5%, 20% {
    transform: rotate(5deg);
  }

  7.5%, 12.5%, 17.5% {
    transform: rotate(-5deg);
  }

  10%, 15% {
    transform: rotate(0deg);
  }
}

.buy-deso-modal {
  max-width: 630px;
}

.buy-deso-tutorial-modal {
  .modal-content {
    margin-bottom: 200px;
  }
}

transfer-deso {
  .search-bar__icon {
    border-radius: 6px 0px 0px 6px;
  }
  search-bar {
    .search-bar__results-dropdown {
      margin-top: 14px;
    }
    .search-bar {
      height: 48px;
      margin-top: 8px;
    }
    input {
      height: 48px !important;
    }
  }
  simple-profile-card {
    .simple-profile-card__container {
      padding-top: 0px;
      padding-bottom: 0px;
      margin-top: 18px;
    }
    .container {
      margin-top: 28px;
      margin-bottom: 6px;
    }
  }
  .mint-nft__sub-title {
    margin-top: 30px;
  }
}

.viewport {
  height: 600px;
  overflow-y: auto;
}

.cdk-overlay-container{
  z-index: 1050;
}

.update-profile__username {
  margin-top: 16px;
}

modal-container {
  .update-profile-modal {
    max-width: 400px;
  }
}

.buy-creator-scroll-container {
 height: calc(100vh - 235px) !important;
}

update-profile {
  .update-profile__holder {
    border-radius: 0;
  }
  .update-profile__avatar-title {
    display: none;
  }
  .update-profile__image {
    height: 120px;
    width: 120px;
    border-radius: 100px;
  }
  button {
    margin-top: 10px;
    i-feather {
      height: 16px !important;
      width: 16px !important;
      margin-right: 10px;
    }
    border: 1px solid var(--tile-bg);
    background: none;
    border-radius: 6px;
    padding-top: 2px;
    color: var(--tile-bg);
    height: 32px;
    width: 136px;
    font-size: 13px;
    line-height: 20px;
  }
  .form-control,textarea {
    border: none;
    border-radius: 6px;
    background: var(--tile-secondary-bg) !important;
    resize: none;
  }
  .update-profile__cc-percent-symbol {
    position: absolute;
    right: 25px;
    top: 5px;
    i-feather {
      height: 16px;
      width: 16px;
    }
  }
}

create-profile-tutorial-page {
  .update-profile__holder,.update-profile__avatar,.update-profile__text {
    border-radius: 0px;
    box-shadow: none;
  }
  .update-profile__holder {
    border-right: 1px solid var(--border);
    border-left: 1px solid var(--border);
  }
}

create-post-tutorial-page {
  feed-create-post {
    background: var(--tile-bg);
  }
}

creator-diamonds {
  .creator-diamonds__no-diamonds {
    padding-bottom: 70px;
    background: var(--tile-bg);
  }

  .creator-diamonds__tab-container {
    background: var(--tile-bg);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .creator-diamonds__header {
    background: var(--tile-bg);
    padding: 0px 16px;
    color: var(--text-muted);
  }
  .diamond-row {
    background: var(--tile-bg);
    padding: 0px 16px;
  }
  .diamond-total-row {
    background: var(--tile-bg);
    padding: 0px 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.creator-profile-nfts__header {
  background: var(--tile-bg);
  height: 80px;
  border: 1px solid var(--border);
  border-right: 0;
  &.card-view {
    margin-bottom: 12px;
  }
}

.creator-profile-nfts__no-nfts {
  padding: 70px 0px;
  background: var(--tile-bg);
}

creator-profile-nfts {
  .card-view.bid-view {
    feed-post {
      .feed-post-container {
        border-radius: 12px 12px 0px 0px;
        margin-bottom: 0px;
        border-bottom: none !important;
        &:first-of-type {
          border-top: none;
        }
      }
      .feed-post__nft-footer {
        border-radius: 0px !important;
      }
    }
    .creator-profile__bid-details {
      background: var(--nft-section-bg);
      color: var(--text);
      margin-bottom: 12px;
      position: relative;
      top: -1px;
      border-top: 1px solid #ccc;
      border-right: 0;
      padding-top: 6px;
    }
  }
}

.creator-profile-nfts__dropdown {
  background: var(--tile-secondary-bg);
  color: var(--tile-secondary-bg);
  border-radius: 6px;
  border: 1px solid var(--border);
  height: 48px;
  width: 193px;
  margin-left: 16px;
  color: var(--text);
  font-size: 15px;
  text-align: left;
  padding: 12px;
  position: relative;
  i-feather {
    position: absolute;
    right: 12px;
  }
}

.creator-profile-nfts__dropdown-menu {
    left: -55px;
}

creator-profile-nfts {
  .creator-profile-nfts__list-container {
    background: var(--tile-bg);
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid var(--border);
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
  }
  .last-item {
    .creator-profile-nfts__list-container {
      border-radius: 0px 0px 10px 10px;
    }
  }
  .feed-post__avatar-container {
    height: 50px;
    width: 50px;
  }
  .creator-profile-nfts__list-body {
    width: 100%;
  }
  .creator-profile-nfts__nft-image-preview {
    height: 50px;
    width: 50px;
    border-radius: 4px;
  }
}

.buy-deso__container {
  background: var(--tile-bg);
  border-radius: 6px;
  padding-top: 16px;
}

.buy-deso__currency-dropdown {
  max-height: 45vh;
  overflow-y: scroll;
}

.buy-deso__country-selector {
  border: 1px solid var(--border);
  border-radius: 6px;
  background: var(--tile-secondary-bg);
  width: 105px;
  height: 48px;
  padding: 12px;
  color: var(--text-secondary);
  font-size: 15px;
}

.buy-deso__country-dropdown {
  max-height: 28vh;
  overflow-y: scroll;
  right: -110px !important;
}

.buy-deso__copy-button {
  display: flex;
  align-items: center;
  justify-content: middle;
  border: solid 1px $buy-button-blue;
  color: $buy-button-blue;
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;
}

.buy-deso__copy-button:hover {
  background-color: $buy-button-blue;
  color: white;
}

.buy-deso__title-holder {
  position: relative;
  top: 20px;
}

.buy-deso__amount-background {
  background-color: var(--background);
}

.buy-deso__box-label-adjustment {
  position: relative;
  width: fit-content;
  background-color: var(--background) !important;
  top: -13px;
}

.messages-header-icon-row__icon {
  font-size: 20px;
  border-radius: 25%;
  margin-right: 2px;
  &:hover {
    background-color: var(--background-secondary);
  }
}

.messages-page__header {
  border: 1px solid var(--border);
  border-top: 0;
  padding: 0px 16px;
  background: var(--tile-bg);
  height: 80px;
  span {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.012em;
    font-weight: 600;
  }
  button {
    width: 156px;
    height: 48px;
    background: var(--highlight);
    color: var(--tile-bg);
    border-radius: 6px;
    font-size: 17px;
    line-height: 24px;
    border: none;
  }
}

.messages-inbox__desktop-column {
  width: 100%;
  max-width: 380px;
  border-left: solid 1px var(--border);
  border-right: solid 1px var(--border);

}

.messages-inbox__threads {
  overflow-y: scroll;
  // Subtract the height of the search and header
  height: 100% !important;
}

.messages-inbox__header {
  height: 72px;
  border-bottom: 1px solid var(--border);
}

.messages-inbox__custom-settings {
  position: absolute;
  right: 16px;
  top: 24px;
}

.messages-filter-menu__container {
  position: absolute;
  background: var(--tile-bg);
  top: 10px;
  left: 10px;
  right: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.messages-filter-menu__title {
  font-size: 15px;
  font-weight: 600;
  margin: 12px 16px;
  color: var(--text-secondary);
}

.messages-filter-menu__dropdown {
  border-radius: 16px !important;
  text-align: left;
  padding: 8px 12px;
  background: var(--tile-secondary-bg);
  position: relative;
  left: 16px;
  width: 80%
}

.messages-filter-menu__checkboxes {
  button {
    margin-left: 16px;
  }
  span {
    margin-left: 9px;
    font-weight: 500;
    font-size: 15px;
  }
}

.messages-filter-menu__checkbox {
  margin: 0;
  float: left;
}

.messages-filter-menu__buttons {
  margin-top: 20px;
  margin-left: 16px;
  font-size: 17px;
  color: var(--text-secondary);
  button {
    border-radius: 6px;
    margin-right: 24px;
    width: 94px;
    height: 48px;
    background: var(--highlight);
    color: var(--tile-bg);
    &:hover {
      color: var(--tile-bg);
    }
  }
}

.checkbox-circle {
  i-feather {
    display: none;
    position: relative;
    top: -5px;
  }
  padding: 3px;
  width: 22px;
  height: 22px;
  border: 1px solid var(--border);
  border-radius: 100%;
  background: none;
  transition: background-color .5s;
  &.radio {
    transition: background-color 0s;
  }
  &.checked {
    background: var(--highlight);
    i-feather {
      display: inline;
      color: var(--tile-bg);
    }
    &.radio {
      transition: background-color .5s;
    }
  }
}

.messages-mobile__new-message-circle {
  z-index: 3;
  position: fixed;
  right: 10px;
  bottom: 90px;
  height: 58px;
  width: 58px;
  text-align: center;
  border: none;
  color: var(--text-inverted);
  line-height: 0.65;
  background: var(--highlight);
  border-radius: 100%;
  &:hover {
    color: var(--text-inverted);
  }
}

.messages-thread__container {
  background: var(--background);
  &.messages-inbox__selected-thread {
    background: var(--text-inverted);
  }
}

.messages-thread__avatar {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 100px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.messages-thread__desktop-column {
  width: 100%;
  max-width: 600px;
  border-right: solid 1px var(--background-secondary);
}

.messages-thread__border-radius {
  border-radius: 6px;
}

.messages-thread__ellipsis-restriction {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.messages-thread-view__container {
  position: relative;
  background: var(--tile-bg);
  border-right: 1px solid var(--border);
}

.messages-thread-view__message-builder {
  position: fixed;
  bottom: 0px;
  height: 120px;
  width: 593px;
  background: var(--tile-bg);
  border-top: 1px solid var(--border);
  textarea {
    padding: 16px;
    border: none;
    border-radius: 0px;
    resize: none;
    outline: none;
    &:focus {
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  button {
    width: 89px;
    height: 48px;
    background: var(--highlight);
  }
}

.message-recipient-modal__header {
  height: 80px;
  background: var(--tile-bg);
  padding: 16px;
  search-bar {
    .border-bottom {
      border: none !important;
    }
    .search-bar {
      width: 343px;
      height: 48px;
    }
    input,#searchBarRoot {
      height: 48px;
    }
    .search-bar__results-dropdown {
      border-radius: 0px 0px 10px 10px;
      position: absolute;
      top: 80px;
      left: 0px;
      margin-top: 0px;
      background: var(--background);
    }
  }
}

.message__min-height {
  min-height: 40px;
}

.message__avatar {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 100px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.message__avatar-placeholder {
  width: 40px;
  min-width: 40px;
  border-radius: 6px;
}

.messages-page__container {
  // This is the height of the entire view, minus the heights of the top-bar, margins, and bottom message constructor
  height: calc(100vh - 190px);
}

.messages-page__height-div,#messagesContainer {
  height: calc(100vh - 180px);
}

.message__sender-bubble-color {
  background-color: var(--nft-section-bg);
  color: var(--text);
}

.message__non-sender-bubble-color {
  background-color: var(--background);
  color: var(--text-secondary);
}

.message-thread__unread-light-blue {
  background-color: var(--unread);
}

.admin__get-user-admin-data-line {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  margin-right: 5px;
}

.admin__add-to-feed-button {
  border: 1px solid lightgrey;
  border-radius: 3px;
  width: fit-conteNt;
  cursor: pointer;
}

.admin__add-to-feed-button:hover {
  color: green;
  border-color: green;
}

.admin__remove-from-feed-button {
  color: green;
  border: 1px solid green;
  border-radius: 3px;
  width: fit-content;
  cursor: pointer;
}

.admin__remove-from-feed-button:hover {
  color: red;
  border-color: red;
}

//
// Bottom bar (mobile)
//
.bottom-bar-mobile__create-post-circle {
  z-index: 3;
  position: absolute;
  top: -70px;
  right: 22px;
  height: 55px;
  width: 58px;
  text-align: center;
  border: none;
  color: var(--tile-bg);
  line-height: 0.65;
  background: var(--text) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-bar-mobile__text{
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.01em;
}


//
// Creators leaderboard
//

.creators-leaderboard__avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.creators-leaderboard__width {
  width: 100%;
  max-width: 980px;
}

.creators-leaderboard__controls-column {
  min-width: 185px;
  max-width: 185px;
}

//
// Buy creator coin
//
.trade-creator-form__currency-select {
  background-color: #fafafa;
  border: none;
  font-size: 14px;
  width: 110px;
}

.trade-creator-form__buy-sell-toggle {
  margin-top: 3px;
}

.trade-creator__avatar {
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 5px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

trade-creator-form {
  search-bar {
    .search-bar__fix-active {
      height: 48px;
    }
  }
}

//
// Not found page
//
.not-found__content-container {
  margin-top: 100px
}

// Notifications
.notifications__avatar {
   height: 40px;
   width: 40px;
   border-radius: 100px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
}

.notifications__action {
  margin-left: 14px;
  font-size: 14px;
}

.notifications__icon {
  position: absolute;
  right: 6px;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: var(--tile-bg);
}

@media (max-width: 992px) {
  app-notifications-page {
    .global__content__flex {
      margin-top: 0px;
    }
    #header-content-spacer {
      margin-top: 60px;
    }
  }
}

sign-up {
  .nft-modal-container {
    margin-top: 50px;
    padding: 20px;
  }

  .main,
  .global__nav__fixed,
  .global__nav__width,
  .global__nav__flex,
  .global__content__flex,
  .global__sidebar__fixed {
    border: none !important;
  }

}

app-notifications-list {
  .global__top-bar {
    position: relative;
    height: 50px;
    background: var(--tile-bg);
    @media (min-width: 992px) {
      height: 72px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border: 1px solid var(--border);
      border-right: 0;
      border-top: 0;
    }
  }
  .notifications__list-item{
    border-left: 1px solid var(--border);
    border-right: 0 solid var(--border);
    border-bottom: 1px solid var(--border);
    background: var(--tile-bg);
    &.last-item {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .feed-post-container {
      border-left: 0;
    }
  }
}

// Landing page
.landing__ticker {
  padding: 20px;
  background-color: var(--background);
}

.landing__header {
  padding: 30px 20px;
}

.landing__logo {
  height: 28px;
  flex: 0 0 130px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  text-indent: -9999px;

  @media (max-width: 992px) {
    flex: 0 0 100px;
  }
}

.landing__nav-link {
  color: var(--text);
  margin-left: 25px;
  font-size: 13px;
}

.landing__nav-link:first-child {
  margin-left: 0;
}

.landing__nav-link.btn {
  color: #FFF;
}

.landing__nav-buy {
  height: 30px;
  font-size: 13px;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--background-secondary);
  color: var(--text);
  border-color: var(--border);
}

.landing__hero {
  padding: 100px 0;
  border-left: 1px solid var(--background-secondary) !important;
  border-right: 1px solid var(--background-secondary) !important;
  color: var(--text);
}

.landing__sign-up {
  margin-top: 30px;
  width: 225px;
  border-radius: 6px;
  padding: 10px;
}

.landing__log-in {
  margin-top: 10px;
  font-size: 13px;
  color: #777;
}

.landing__buy {
  padding: 7px 20px;
  border-radius: 6px;
}

.landing__prop {
  margin-top: 100px;
  background: #C4C4C426;
  border-radius: 25px;
  padding-top: 50px;
}

.landing__prop-header {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.landing__prop-desc {
  color: #777;
  font-size: 14px;
  margin-bottom: 30px;
  padding: 0 20px;
  font-family: Roboto;
}

.landing__example {
  padding: 30px 50px;
  padding-bottom: 0px;
  background-color: var(--background);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

// fade to white
.landing__example:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), var(--background-secondary) 90%);
  width: 100%;
  height: 4em;
}

.landing__btn-cta {
  margin-top: 30px;
  padding: 7px 30px;
  border-radius: 6px;
}

.landing__platform {
  padding: 100px 0;
  border-top: 1px solid var(--background-secondary) !important;
  border-right: 1px solid var(--background-secondary) !important;
  border-left: 1px solid var(--background-secondary) !important;
}

.landing__platform-header {
  font-weight: bold;
  font-size: 35px;
  color: var(--text);
  padding: 0 20px;
}

.landing__platform-desc {
  font-size: 18px;
  margin-top: 30px;
  padding: 0 20px;
}

.landing__echo {
  background-color: var(--background);
  border-top: 1px solid var(--background-secondary);
  padding: 50px 0;
}

.landing__footer {
  padding: 50px 80px;
}

.landing__footer-links {
  display: flex;
  flex-direction: column;
}

.landing__footer-links a {
  font-size: 12px;
  color: #222;
  margin-bottom: 7px;
}

.landing__footer-links hr {
    border-top: 1px solid black;
    width: 200px;
    padding: 0;
    margin-left: 0;
}

.landing__board {
  margin-bottom: 0;
}

.landing__board img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

.landing__board th {
  padding: 10px 0;
}

.landing__board td {
  vertical-align: middle;
  padding: 10px 0;
}

.landing__board tr {
  border-bottom: 1px solid #DDD;
}

.landing__post-img {
  height: 50px;
  width: 50px;
  border-radius: 5px;
}


//
// Manage follows page
//

.manage-follows__avatar {
  display: block;
  height: 50px;
  width: 50px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.manage-follows__avatar-container {
  width: 56px; // TODO: figure out why chrome doesn't render this as 50px
  margin-right: 15px;
}


//
// Simple profile card (for likes, reposts, diamonds modal)
//

.simple-profile-card__avatar {
  display: block;
  height: 50px;
  width: 50px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.simple-profile-card__avatar-container {
  width: 50px;
  margin-right: 15px;
}

//
// (end) v0 styles
//

@media (min-width: 992px) {
  .info-tooltip {
    position: relative;
    cursor: pointer;
  }
}
@media (max-width: 992px) {
  // TODO: Fix tooltips on mobile. Consider just
  // making them all use sweetalert2 with "question."
  .info-tooltip {
    display: none !important;
    //position: relative;
    //cursor: pointer;
    //color: $tooltip-color;
    //font-weight: bold;
  }
}
/* Apply only to screen sizes >= sm */
.info-tooltip-contents {
  position: absolute;
  text-align: left;
  top: -.5rem;
  left: 1.5rem;
  background-color: $light-color;
  cursor: pointer;
  border: 1px solid $dark-in-between-color;
  padding: .5rem;
  color: $dark-color;
  width: 15rem;
  z-index: 1;
  font-size: 1rem;
  font-weight: bold;;
}


.btn.disabled {
  // I don't think bootstrap's button opacity makes buttons look sufficiently disabled,
  // so I'm overriding bootstrap here.
  opacity: 0.4
}

.btn.btn-light {
  cursor: pointer;
  font-size: $button-font-size;
  min-height: $button-height;

  transition: 0s;
  background-color: $button-color-default;
  color: $button-text-color-default !important;
  border-radius: 5px;
  border: none;
  font-weight: 700;

  display: inline-flex;
  width: fit-content;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $button-hover-color-default;
  }
  &:active {
    background-color: $button-color-pressed-default !important;
  }
}

.btn-default {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.divtext {
  display: flex;
  align-items: center;
  justify-content: center;
}
.separating-line {
  height: 1px;
  width: 100%;
  background-color: $border-color;
}
.title {
  display: inline-block;
  color: $important-text-color;
  font-size: $big-text-size;
  font-weight: bold;
}
.subtitle {
  display: inline-block;
  color: $light-color;
  text-decoration: underline;
  font-size: $big-text-size;
}
.form-label {
  color: $important-text-color;
  font-size: $normal-text-size;
}
.form-control {
  color: var(--text) !important;
  background: var(--formbg) !important;
  border-color: var(--border) !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.normal-text {
  color: $text-color;
  font-size: $normal-text-size;
}
.warning-text {
  color: $error-color;
  font-weight: bold;
  font-size: $normal-text-size;
}
input {
  background-color: transparent;
  border: 1px solid $border-color;
  color: $important-text-color;
  padding-left: .5rem;
  padding-right: .5rem;
  &:focus {
    outline: none;
  }
}
::placeholder {
  color: $placeholder-color;
}
textarea {
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--text);
  padding-left: .5rem;
  padding-right: .5rem;
  max-width: 100%;
  &:focus {
    outline: none;
  }
}
.emph {
  color: $important-text-color;
  display: contents;
}
.btn.btn-light.yes {
  color: $good-color;
  border-color: $good-color;
  font-weight: bold;
  background-color: $separating-line-color;
}
.btn.btn-light.no {
  color: $bad-color;
  border-color: $bad-color;
  font-weight: bold;
  background-color: $separating-line-color;
}
em {
  color: $important-text-color;
}
.needs-basic-form {
  .subtitle {
    margin-top: 1rem;
  }
  .form-label {
    display: inline-block;
    width: 12rem;
    vertical-align: top;
  }
  .input-container {
    margin-top: 1rem;
    .random-username {
      margin-left: 1rem;
    }
  }
}
.next-back-container {
  margin-top: 1rem;
  .btn.btn-light {
    width: 7.5rem;
    margin-right: 1rem;
  }
}
.help-text {
  margin-top: 1rem;
}
.action-items-badge {
 width: auto;
 padding-left: .5rem;
 padding-right: .5rem;
 background-color: $error-color;
 border-radius: 1rem;
 display: flex;
 align-items: center;
 justify-content: center;
 color: $button-text-color-default;
 font-weight: bold;
}
.minus {
  color: $light-color;
  display: inline-block;
  margin-right: .5rem;
  cursor: pointer;
}
.text-underline {text-decoration: underline;}
.link {
    color: $light-color;
    text-decoration: underline;
    cursor: pointer;
    display: inline;
}
.file-upload-button::-webkit-file-upload-button { cursor:pointer; }

@media (min-width: 992px) {
  /* Apply only to screen sizes >= sm */
  div.hide-on-desktop {
    display: none !important;
  }
}

@media (max-width: 992px) {
  /* Apply only to screen sizes >= sm */
  div.hide-on-mobile {
    display: none !important;
  }
  .reverse-columns-on-mobile {
    flex-direction: column-reverse;
  }
  [class*="col-"] {
      margin-bottom: 15px;
  }
}

/*Begin DeSo config*/
.flex-center {
  display: flex;
  align-items: center;
}
input {
  color: var(--text);
  width: 100%;
}
.red {
  color: $error-color;
}
.btn.green {
  background-color: $important-button;
}
.btn.orange {
  background-color: $profile-button;
}
.btn-primary {
  background-color: $buy-button-blue;
  border-color: $buy-button-blue;
  color: #fff;
}

.btn-primary:hover {
	color: #fff;
	background-color: var(--hover);
	border-color: var(--hover);
}

.btn-primary.disabled, .btn-primary:disabled {
	color: #fff;
	background-color: $buy-button-blue;
	border-color: $buy-button-blue;
}

.diamond-btn-secondary {
  background-color: #FFFFFF;
  border-color: var(--border);
}
#foobarxyz p {
  margin: 0;
}
#foobarabc p {
  margin: 0;
}
.post-submitted p {
  margin: 0
}
.grey {
  color: $grey;
}

// Animations
.slide-up,
.slide-up-1,
.slide-up-2,
.slide-up-3 {
  animation: slide-up 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  visibility: visible !important;
}

.slide-up-1 {
  animation-delay: 0.1s;
}

.slide-up-2 {
  animation-delay: 0.2s;
}

.slide-up-3 {
  animation-delay: 0.3s;
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// Added BootStrap and Custom CSS for Themeing
.border-bottom {
  border-bottom: 1px solid var(--border) !important;
}

.border-top {
  border-top: 1px solid var(--border) !important;
}

.border-left {
  border-left: 1px solid var(--border) !important;
}

.border-right {
  border-right: 1px solid var(--border) !important;
}

.border-none {
  border: none !important;
}

.text-black {
  color: var(--text) !important;
}

.table {
  color: var(--text) !important;
}

.deso {
  filter: var(--filter);
}

.selector {
  background: var(--background-secondary);
  outline: none;
  border-radius: 5px;
  padding: 5px;
  color: var(--text);
}

.selector option:active, .selector option:hover {
  outline-color: blue;
}

.notification {
  text-align: center;
  line-height: 18px;
  padding: 0px 5px;
  color: red;
  font-weight: 700;
}

.bg-tile {
  background-color: var(--tile-bg) !important;
}

.bg-light {
  background-color: var(--background-secondary) !important;
}

.bg-highlight {
  background-color: var(--highlight);
}

.bg-highlight-secondary {
  background-color: var(--highlight-secondary);
}

// Animations by Carsen Klock @carsenk

.is_animating {
  animation: pulse-red 2s;
}

.is_animating_ice {
  animation: pulse-blue 2s;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.modalinput {
  border-color: var(--mborder) !important;
}

.comment-modal {
  color: var(--text);
  background-clip: padding-box;
  opacity: 1;
  flex-direction: column;
  width: 100%;
  display: flex;
  outline: 0;
  position: relative;
}

.ddropdown {
  background: var(--background);
  color: var(--text);
  border-bottom: 1px solid var(--border);
}

.ddropdown option {
  background: var(--background);
  color: var(--text);
  border-bottom: 1px solid var(--border);
}

.secalt-bg {
  background-color: var(--secalt);
}

.formbg {
  background-color: var(--formbg) !important;
}

.modal-dialog {
  max-width: 630px;
}

.modal-dialog-thin {
  max-width: 360px;
}

.modal-content {
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  background: none;
  border: none;
  position: relative;
  background-clip: padding-box;
  opacity: 1;
  flex-direction: column;
  width: 100%;
  display: flex;
  outline: 0;
  comment-modal {
    .feed-post__container {
      padding: 16px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .feed-post-container {
      margin-bottom: 0px;
    }
    .feed-create-comment-form__reply-container {
      background: var(--tile-bg);
      margin-left: 0px;
      padding: 16px;
      border-top: 1px solid var(--border);
      font-size: 15px !important;
      color: var(--text-muted);
      span {
        font-weight: 600;
      }
    }
  }
}

.modal-header {
  padding: 0px;
  border: none;
  .modal-header-x {
    background: var(--tile-bg);
    height: 56px;
    width: 56px;
    border-radius: 32px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    i-feather {
      font-size: 0px;
    }
  }
  i-feather {
    color: var(--text);
    height: 24px;
    width: 24px;
    position: relative;
  }
}

.feed__tag-container {
  background: var(--tile-secondary-bg);
  width: 100px;
  border: none;
  height: 32px;
  border-radius: 10px;
  color: var(--text-secondary);
  z-index: 2;
  &.feed__tag-container-expanded {
    width: 10px;
  }
  &.active {
    color: var(--highlight);
  }
}

.feed__tag-container-mobile {
  position: fixed !important;
  margin-left: 10px;
  top: 68px;
  left: 205px;
  z-index: 1;
  &.logged-out {
    top: 99px;
  }
}

.feed__tag-container-background {
  background: var(--tile-secondary-bg);
  position: relative;
  left: -30px;
  border: none;
  height: 32px;
  width: 30px;
  border-radius: 10px;
  z-index: 1;
  &.feed__tag-container-expanded {
    cursor: auto;
    padding: 3px;
    width: 180px;
    transition: width .5s;
  }
}

.feed__tag-input {
  z-index: 2;
  width: 100px;
  top: 0px;
  left: 0px;
  height: 32px;
  color: var(--text-secondary);
  border: none;
  &.feed__tag-container-expanded {
    cursor: auto;
    display: block;
    width: 175px;
    padding-left: 25px;
  }
}

.creator-profile__container {
  background: var(--tile-bg);
  border-radius: 0;
  border: 1px solid var(--border);
  border-top: 0;
  border-right: 0;
  padding: 16px;
  padding-top: 80px;
  margin-bottom: 15px;
  background-postition: bottom 20px;
  &.creator-profile__container-cover{
    &:before {
      background-image: var(--cover-image);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &:before {
    background: var(--tile-secondary-bg);
    content: "";
    height: 175px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    @media (max-width: 992px) {
      height: 130px;
    }
  }
  .creator-profile__top-card-profile-row {
    margin-top: 120px;
    @media (max-width: 992px) {
      margin-top: 80px;
    }
  }
  .js-creator-profile-top-card-container {
    position: relative;
    padding-bottom: 0px;
    i-feather[name=check-circle] {
      position: relative;
      top: 6px;
    }
  }
}

creator-diamonds {
  .tab-selector__container{
    margin-bottom: 6px;
    border-top: 1px solid var(--border);
  }
}

creator-profile-details {
  .tab-selector__container{
    margin-bottom: 6px;
  }
  .creator-profile-details__coin-container {
    .font-weight-bold {
      font-weight: 600 !important;
    }
  }
  .creator-profile-details__coin-info {
    background: var(--nft-section-bg);
    color: var(--text);
    font-size: 13px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

creator-profile-hodlers {
  .creator-profile-hodlers__header {
    padding-top: 16px;
    background: var(--tile-bg);
    border-radius: 0 !important;
    border-top: 1px solid var(--border);
  }

  .creator-profile-hodlers__row:first-child {
    padding: 6px 16px;
  }

  .creator-profile-hodlers__row:not(:first-child) {
    padding: 6px 16px;
    background: var(--tile-bg);
  }
  .total-row {
    border-radius: 0px 0px 10px 10px;
  }
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 56px;
}

.likes-modal-container, .quote-reposts-modal-container, .reposts-modal-container, .diamonds-modal-container {
  background-color: var(--tile-bg);
  color: var(--text);
  border: 1px solid var(--border);
  border-radius: 6px;
  simple-profile-card {
    .simple-profile-card__container {
      padding: 16px;
    }
  }
}

mint-nft-page {
  search-bar {
    .search-bar.input-group {
      border: 1px solid var(--border);
    }
    #searchbar {
      height: 46px;
    }
  }
}

mint-nft-page, sell-nft-page {
  .global__nav__fixed {
    border: none;
  }
}

.mint-nft__section {
  padding: 40px;
  border-bottom: 1px solid var(--border);
}
.mint-nft__text {
  color: var(--text-muted);
  size: 15px;
}
.mint-nft__title {
  color: var(--text);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
}
.mint-nft__sub-title {
  color: var(--text-muted);
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 12px;
  margin: 16px 0px 8px 0px;
}
.mint-nft__type-selector {
  width: 40%;
  height: 186px;
  border: 1px solid var(--border);
  border-radius: 6px;
  transition: border .5s;
  &.nft-type-selected {
    border-color: var(--highlight);
    .mint-nft__type-selector-title {
      color: var(--highlight);
    }
  }
}
.mint-nft__type-selector-title {
  font-weight: 600;
  font-size: 15px;
  color: var(--text-secondary);
  margin-top: 12px;
  text-align: center;
  transition: color .5s;
}
.mint-nft__dropdown-btn {
  border: 1px solid var(--border);
  border-radius: 6px 0px 0px 6px;
  background: var(--tile-secondary-bg);
  width: 96px;
  height: 48px;
  padding: 12px;
  color: var(--text-secondary);
  font-size: 15px;
}
.mint-nft__input {
  padding: 12px;
  height: 48px;
  background: var(--tile-secondary-bg);
  border: 1px solid var(--border);
  border-radius: 6px;
  font-size: 15px;
  color: var(--text-secondary);
}
.mint-nft__price-half {
  width: 50%;
  margin-left: 8px;
}
.mint-nft__price-full {
  width: 100%;
  margin-left: 0px;
}
.mint-nft__floating-span {
  position: absolute;
  top: 12px;
  right: 32px;
}
.mint-nft__sub-text {
  font-size: 15px;
  color: var(--text-secondary);
}
.mint-nft__create-btn {
  width: 137px;
  height: 48px;
  border-radius: 6px;
  background: var(--highlight);
  color: var(--tile-bg);
  font-size: 17px;
  margin: 24px 0px 105px 0px;
  border: none;
}

.nft-modal-container {
  color: var(--text);
  border: 1px solid var(--mborder);
  background: var(--tile-bg);
  background-clip: padding-box;
  flex-direction: column;
  width: 100%;
  display: flex;
  border-radius: 6px;
  outline: 0;
  position: relative;
}

.nft-modal-radio-size {
  height: 15px;
  width: 15px;
}

.nft-modal-bid-details {
  background: var(--tile-secondary-bg);
  border-radius: 6px;
  font-size: 15px;
  margin: 16px 0px;
}

.place-bid-rows {
  @media (min-width: 992px) {
    max-height: 250px;
    overflow-y: scroll;
  }
}

sell-nft-modal {
  .mint-nft__section {
    border-bottom: none;
  }
}

.sell-nft__row {
  background: var(--tile-secondary-bg);
  border-radius: 6px;
  padding: 16px 0px;
  margin-bottom: 16px;
  font-size: 15px;
  width: 100%
}

.place-bid-dropdown {
  left: -48px !important;;
}

.danger-button {
  width: 137px;
  height: 48px;
  background: var(--error);
  border-radius: 6px;
  color: var(--tile-bg);
  border: none;
  &:hover {
    color: var(--tile-bg);
  }
  &.disabled-button {
    background: var(--error-light)
  }
}

.primary-button {
  width: 137px;
  height: 48px;
  background: var(--highlight);
  border-radius: 6px;
  color: var(--tile-bg);
  border: none;
  &:hover {
    color: var(--tile-bg);
  }
  &.disabled-button {
    background: var(--highlight-secondary)
  }
}

.secondary-button {
  width: 137px;
  height: 48px;
  background: none;
  border-radius: 6px;
  color: var(--highlight);
  border: 1px solid var(--highlight);
  &:hover {
    color: var(--highlight);
  }
  &.disabled-button {
    background: var(--highlight-secondary)
  }
}

.narrow-button {
  width: 75px !important;
}

.plain-button {
  width: 137px;
  height: 48px;
  background: var(--tile-bg);
  border: 1px solid var(--text-secondary);
  border-radius: 6px;
  color: var(--text-secondary);
  &:hover {
    color: var(--text-secondary);
  }
  &.disabled-button {
    background: var(--tile-bg)
  }
}

.light-button {
  width: 137px;
  height: 48px;
  background: var(--tile-bg);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text);
  font-weight: 600;
  &:hover {
    color: var(--text);
  }
  &.disabled-button {
    background: var(--tile-bg)
  }
}

.trend-dropdown-button {
  border-radius: 6px;
  border: none;
  border: 1px solid var(--border);
  background: var(--tile-secondary-bg);
  height: 48px;
  padding: 12px;
  color: var(--text-secondary);
  font-size: 15px;
}

.nft-modal-separator {
  border-bottom: #AAAAAA solid 1px;
}

.nft-modal-separator-2 {
  border-bottom: #DDDDDD solid 1px;
}

.create-nft-auction-row-border {
  border-bottom: #EEEEEE solid 1px;
}

.interaction-detail {
  &:hover, &:active, &:focus {
    text-decoration: inherit; // I prefer no underline when you hover over links
    color: var(--text-secondary);
  }
  cursor: pointer;
}

[class*='mat-select'], [class*='mat-option-'], .mat-form-field-label, select {
  color: var(--text);
}

.toast-bottom-center {
  width: auto;
  bottom: 20px;
  left: 50vw;
  right: auto;
  pointer-events: auto;
}

.info-toast {
  background: var(--highlight);
  padding: 8px 16px;
  color: var(--tile-bg);
  font-size: 17px;
  border-radius: 4px;
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  right: 50vw;
  .toast-link {
    font-weight: 600;
    margin-left: 16px;
  }
}

.mat-option, option {
  background-color: var(--formbg);
  color: var(--text);
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background-color: var(--background-secondary);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: var(--unread);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: var(--border);
}

.mat-form-field-ripple {
  background-color: var(--link);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--hover);
}

.direct-to-native-browser-container {
  color: var(--text);
  font-size: 20px;
  padding: 16px;
  height: auto;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 500;
  background: var(--tile-bg);
  border-radius: 6px;
  border: 1px solid var(--border);
}

.tutorial-header-hide {
  .introjs-skipbutton {
    display: none;
  }
}

.tutorial-tooltip-right {
  left: auto !important;
  right: 0px !important;
}

.tutorial-tooltip {
  color: var(--text);
  min-width: 300px;
  max-width: 550px;
  font-family: Gilroy Arial sans-serif;
  border-radius: 6px;
  background: var(--tile-bg);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  .introjs-button {
    text-shadow: none;
    border-radius: 12px !important;
    background: none;
    border: 1px solid var(--text-secondary) !important;
    color: var(--text-secondary) !important;
    &:hover {
      color: var(--text-secondary) !important;
    }
    &.introjs-disabled {
      background: var(--tile-bg) !important;
      color: var(--text-muted) !important;
      border-color: var(--text-muted) !important;
    }
  }
}

.swal2-popup {
  z-index: 100000000;
  background-color: var(--tile-bg) !important;
  color: var(--text);
  padding: 40px !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  .swal2-title, .swal2-content {
    color: var(--text);
  }
  button {
    height: 48px;
    min-width: 70px;
    border-radius: 6px !important;
    font-size: 20px !important;
    padding: 6px 16px !important;
  }
  .btn-light {
    background: var(--highlight) !important;
    color: var(--tile-bg) !important;
    border: none;
    &:hover {
      color: var(--tile-bg) !important;
    }
    &.disabled-button {
      background: var(--highlight-secondary) !important;
    }
  }
  .no, .swal2-cancel {
    background: var(--tile-bg) !important;
    border: 1px solid var(--text-secondary) !important;
    color: var(--text-secondary) !important;
    &:hover {
      color: var(--text-secondary) !important;
    }
    &.disabled-button {
      background: var(--tile-bg) !important;
    }
  }

}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0,0,0,0.9) !important;
}

//
// Bootstrap switch custom styling
//

// Add class `.custom-switch-lg` to a `custom-switch`
.custom-switch.custom-switch-lg {
  padding-bottom: 1rem;
	padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: .75rem;
    padding-top: 0.15rem;

    &::before {
      border-radius: 1rem;
      height: 1.5rem;
      width: 2.5rem;
    }

    &::after {
			border-radius: .65rem;
      height: calc(1.5rem - 4px);
      width: calc(1.5rem - 4px);
    }
  }

	.custom-control-input:checked ~ .custom-control-label::after{
  	transform: translateX(1rem);
	}
}

// Add class `.custom-switch-xl` to a `custom-switch`
.custom-switch.custom-switch-xl {
  padding-bottom: 1rem;
	padding-left: 2.25rem;

  & .custom-control-label {
    padding-left: 2rem;
  	padding-top: 0.5rem;

    &::before {
      border-radius: 1rem;
      height: 2rem;
      width: 3.5rem;
    }

    &::after {
      border-radius: 2rem;
      height: calc(2rem - 4px);
      width: calc(2rem - 4px);
    }
  }

	.custom-control-input:checked ~ .custom-control-label::after {
  	transform: translateX(1.5rem);
	}
}

.text-align-center {
  text-align: center;
}

.borderbg {
  background-color: var(--border);
}


.border {
  border: 1px solid var(--border) !important;
}

.cloutavista-button {
  border-radius: 0px 0px 16px 16px;
  font-family: -apple-system, BlinkMacSystemFont,
               "Helvetica Neue", sans-serif;
  position: sticky;
  bottom: 0px;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      height: 1px;
      position: absolute;
      top: 0px;
      width: 100%;
    }
    p {
      color: var(--text) !important;
      margin: 15px 0 15px 0;
      font-size: 15px;
    }
  }
}

.mobile-reaction-box {
  @media(min-width: 600px) {
    display: none !important;
  }
}

.non-mobile-reaction-box {
  @media(max-width: 600px) {
    display: none !important;
  }
}

// Contains the button which triggers diamond pop ups
.diamond-btn {
  @media (max-width: 991.98px) {
    @media (max-width: 600px) {
      bottom: 35px;
    }
    width: 100%;
  }
  width: 44px; height: 30px;
  position: absolute;
  left: -4px;
  cursor: pointer;
  &::before {
    display: none
  }
  // Contains box which contains all of the selectable diamond levels
  .reaction-box {
    // This creates an invisible, hoverable space for our pointer to use without losing hover focus
    // Basically, a bridge between the diamond button and the diamond row
    &::after {
      content: "."; opacity: 0;
      display: block;
      width: 340px;
      // On desktop, move the diamond bar higher to prevent accidental clicking
      @media (max-width: 991.98px) {
        height: 10px;
        top: 50px; left: 0px;
      }
      height: 30px;
      position: absolute;
      top: 50px; left: 0px;
    }

    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
    // Need to position box above top bar when explainer is triggered
    z-index: 30;
    position: absolute;
    width: 340px; height: 55px;
    background: var(--tile-bg);
    border-radius: 20px;
    // On mobile, need to position this box further left so it doesn't leave the screen
    @media (max-width: 991.98px) {
      @media (min-width: 600px) {
        left: -300px;
        bottom: 35px;
      }
      @media (max-width: 600px) {
        left: 0px;
      }
      bottom: 45px;
    }
    left: -125px; bottom: 50px;
    display: none;

    // Each selectable diamond level in the diamond pop-up
    .reaction-icon {
      // Enables "pop-up" animation
      transition: all 0.3s cubic-bezier(.09,1.22,.5,1.04);
      opacity: 0;
      transform: translate(0, 50px) scale(0);
      width: 47px; height: 20px;
      display: inline-block;
      background: var(--background-secondary);
      background-color: transparent;
      content: '\f219';
      border-radius: 15px;
      margin: 8px 0 0 0;
      text-align: center;

      .diamond-reaction {
        width: 30px;
        height: 30px;
        font-size: 25px;
        top: -30px;
        left: -2px;
        position: relative;
        transition: color .3s;
      }

      .diamond-help {
        color: var(--grey);
      }

        label {
        padding: 3px 5px 3px 5px;
        position: relative;
        top: -24px;
        border-radius: 6px;
        font-size: 11px;
        color: #FFF;
        background: #333;
        visibility: visible;
      }

      // Make selectable area smaller, to prevent accidental selections
      .reaction-icon-display {
        height: 14px;
      }
    }
  }

  // Don't show the diamond selector when it's the user's own post
  &.hide-diamonds {
    .reaction-box{
      display: none !important;
    }
  }

  // We want to disable phone-sized devices from showing the selector on hover.
  @media(max-width: 600px) {
    &.dragged-like {
      .reaction-box-icons {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .reaction-box {
        display: block;
        width: 100%;
      }
    }
  }
  // Show the selector on hover for tablets and desktop machines
  @media(min-width: 601px) {
    &:hover {
      .reaction-box {
        display: block;
      }
    }
  }

  // If a diamond is either hovered over or selected via the drag interface, grow it and animate it
  &.dragged-like,&:hover {

    .reaction-box {

      .reaction-icon {

        // Skip the "show" trigger on tablets, the on-hover property can be touchy
        @media (min-width: 601px) and (pointer: coarse) {
          opacity: 1;
          transform: translate(0, 0) scale(1);
        }
        // Show the reaction icon once it gets the "show" class (this is triggered by a timer in JS to achieve the animation)
        &.show {
          opacity: 1;
          transform: translate(0, 0) scale(1);
        }

        // For those elements we want to grow, scale them up when they're hovered
        &.transformable{
          // Prevent hover effects on mobile, we use the drag interface for that
          @media (pointer: fine) {
            &.dragged-icon,&:hover {
              transform: scale(1.3);
              transform-origin: bottom;
            }
          }
          // Scale up icons on mobile when they're dragged across
          @media (pointer: coarse) {
            &.dragged-icon {
              transform: scale(1.3);
              transform-origin: bottom;
            }
          }
        }
      }
    }
  }
}

.feed-reaction {
  position: relative;

  i {
    position: relative;
    font-size: 22px;
  }
}

.diamond-mobile-drag-container {
  display: none
}

.diamond-mobile-drag-instructions {
  display: none;

  p {
    display: none;
  }
}

.diamond-mobile-drag-grab {
  display: none;
}

// Only enable drag controls for smaller screens i.e. phones not tablets
@media (max-width: 600px) {
  .diamond-mobile-drag-container {
    height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    pointer-events: none;
  }

  .diamond-mobile-drag-instructions {
    display: block;
    height: 48px;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    background: var(--norm);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms, background-color 300ms ease;
    border-radius: 6px;

    &.show {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms, background-color 300ms ease;
    }

    p {
      display: block;
      top: 15px;
      text-align: center;
      position: relative;;
      display: table-cell;
      color: var(--secalt)
    }
  }

  .diamond-mobile-drag-grab {
    display: block;
    height: 45px;
    width: 45px;
    position: absolute;
    z-index: 1;
    background: transparent;
    pointer-events: all;
  }
}

.hide-diamonds {
  &.diamond-mobile-drag-grab {
    display: none;
  }
  &.diamond-mobile-drag-container {
    display: none;
    .diamond-mobile-drag-instructions {
      display: none;
      .p {
        display: none;
      }
    }
  }
}

.follow-on-buy {
  *, *:after, *:before {box-sizing: border-box;}

  body {
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  input#check {
    position: absolute;
    opacity: 0;

    &:checked + label svg path {
      stroke-dashoffset: 0;
    }

    &:focus + label {
      transform: scale(1.03);
    }
  }

  #check + label {
    display: block;
    border: 2px solid var(--grey);
    width: var(--d); height: var(--d);
    border-radius: 5px;
    cursor: pointer;
    transition: all .2s ease;

    svg {
      pointer-events: none;
      vertical-align: top;
      path {
        fill: none;
        stroke: var(--grey);
        stroke-width: 11px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 100;
        stroke-dashoffset: 101;
        transition: all 350ms cubic-bezier(1,0,.37,.91);
      }
    }
  }

  .follow-check {
    margin-top: 4px;
    margin-right: 10px;
  }
}

// Prevent text highlighting on mobile long press
.unselectable {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.desktop-creator-coin-info {
  @media (max-width: 992px) {
    display: none;
  }
  display: inline;
}

.mobile-creator-coin-info {
  @media (max-width: 992px) {
    display: inline;
    margin-left: 0px;
    padding-left: 0px;
  }
  display: none;
}

.creator-coin-info {
  @media (max-width: 992px) {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: 0rem !important;
  }
  padding-left: .5rem !important;
  padding-right: .5rem !important;
  margin-left: .5rem !important;
}

.referral-link-info__blue-border {
  border-left: 5px solid $buy-button-blue !important;
}

.create-long-post__form-container {
  padding-top: 50px;
}

.create-long-post__form-header {
  display: flex;
  padding: 24px 24px 0 24px;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
}

.create-long-post__primary-actions {
  @media (max-width: 992px) {
    align-self: flex-end;
  }
}

.ql-container {
  border: none !important;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3 {
  margin-top: 14px;
  line-height: 1.5em;
  margin-bottom: 22px;
}

.ql-editor h1 {
  font-size: 26px !important;
}
.ql-editor h2 {
  font-size: 22px !important;
}
.ql-editor h3 {
  font-size: 18px !important;
}

.ql-editor p,
.ql-editor ul,
.ql-editor li {
  font-size: 16px;
  line-height: 1.6em;
}

.ql-toolbar.ql-snow {
  margin: 0 auto;
  width: 973px;
  margin-top: 12px;
  background-color: white;
  z-index: 100;
  top: 50px;
  border-left: none;
  border-right: none;
  position: fixed;

  @media (max-width: 992px) {
    top: 47px;
    width: 100%;
  }
}

.ql-editor ul > li.ql-indent-1::before {
  content: '\25AB';
}

.ql-editor ul > li.ql-indent-2::before {
  content: '\25AA';
}

.ql-editor ul > li.ql-indent-3::before {
  content: '\25E6';
}

.ql-editor.ql-blank::before {
  left: inherit !important;
  right: inherit !important;
}
// Editor Classes
.editor-publish-button {
  border: 0;
  padding: 8px 14px;
}
.editor-content .ql-editor {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
}

// Article Classes
.article-content .ql-editor {
  min-height: 300px !important;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
  padding: 0;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
}

.article-header {
  padding: 40px 0px 10px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto 20px;
  @media (max-width: 992px) {
    padding: 40px 20px 10px;
  }
}
.article-cover-image-wrapper {
  width: 90%;
  margin: 0 auto 20px;
  height: 500px;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  @media (max-width: 992px) {
    height: 300px;
  }
}
.article-reactions {
  width: 100%;
  max-width: 700px;
  margin: 30px auto 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 0 0 12px 0;
  @media (max-width: 992px) {
    padding: 0 20px 12px;
  }
}
.article-recent-posts {
  width: 100%;
  max-width: 700px;
  margin: 40px auto;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
}
.article-comments {
  width: 100%;
  max-width: 700px;
  margin: 40px auto 0;
  border-right: 1px solid #ddd;
}
.ql-align-center,
.ql-align-justify {
  text-align: left !important;
}

.ql-mention-list {
  list-style-type: none;
  padding: 0;
  border: 1px solid var(--border);
}

.ql-mention-list-container {
  padding-left: 40px;
}

.ql-mention-list-item {
  font-size: medium;
  padding: 8px;
  &.selected {
    background: var(--tile-secondary-bg);
  }
}

.ql-snow .ql-editor {
  & .mention, & a, & a em {
    color: var(--link);
    text-decoration: none;
  }
  & em {
    color: var(--text-secondary);
  }
}
