// Greenish Theme
// By: @mrpreet
.greenish[app-theme] {
    --background: #03353E;
    --text: #ffffff;
    --grey: #ffffff;
    --background-secondary: #00515A;
    --secalt: #001E24;
    --textalt: #eee;
    --norm: #FFFFFF;
    --formbg: #001E24;
    --link: #03A8BB;
    --hover: #0294A5;
    --border: #00515A;
    --mborder: #00515A;
    --filter: invert(98%) sepia(1%) saturate(264%) hue-rotate(181deg) brightness(116%) contrast(100%);
    --unread: #034753;
    --topbar: #034753;
    --cblue: #238eff;
    --cred: #e0245e;
    --cgreen: #17BF63;
    --button: #03A8BB;
    --loading: #ffffff;
}
