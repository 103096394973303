// Cake Theme
// By: @brixboston100
.cake[app-theme] {
    --background: #f8edeb;
    --text: #47205c;
    --grey: #6d4e75;
    --background-secondary: #f3e1e1;
    --secalt: #fae1dd;
    --textalt: #a97aa2;
    --norm: #47205c;
    --formbg: #fff5f3;
    --link: #009aff;
    --hover: #008cad;
    --border: #fae1dd;
    --mborder: #fae1dd;
    --filter: grayscale(0.5) sepia(1) hue-rotate(227deg) saturate(9.2) opacity(0.78);
    --unread: #f3e1e1;
    --topbar: #ead8d8;
    --cblue: #457b9d;
    --cred: #ff3298;
    --cgreen: #69ab4f;
    --button: #009aff;
    --loading: #d2a59d;
  }
