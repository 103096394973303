// Legends Theme
// By:
.legends[app-theme] {
    --background: #1c1812;
    --text: #EFF3F8;
    --grey: #999;
    --background-secondary: #2d261c;
    --secalt: #14110e;
    --textalt: #eee;
    --norm: #eee;
    --formbg: #14110e;
    --link: #D3B882;
    --hover: #e5c075;
    --border: #382418;
    --mborder: #382418;
    --filter: invert(98%) sepia(1%) saturate(264%) hue-rotate(181deg) brightness(116%) contrast(100%);
    --unread: #1a293f;
    --topbar: #16130d;
    --cblue: #238eff;
    --cred: #e0245e;
    --cgreen: #17BF63;
    --button: #D3B882;
    --loading: #999;
  }
